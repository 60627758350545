// Libraries
import { Box, Stack, Typography } from '@mui/material';

// Scss
import './MobileNotification.scss';

// Files
import BluonLogo from '../../assets/images/bluon-logo-trademark.png';

const MobileNotification = () => {
  return (
    <Box className='mobile-notification'>
      <Stack className='mobile-notification__body' spacing={4}>
        <Typography variant='h3'>Grab your computer!</Typography>

        <Typography>This tool is unavailable on mobile.</Typography>

        <Typography>
          Please open the link in a desktop browser to continue.
        </Typography>
      </Stack>

      <Box className='mobile-notification__logo'>
        <img src={BluonLogo} alt='bluon-logo-trademark' />
      </Box>
    </Box>
  );
};

export default MobileNotification;
