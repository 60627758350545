import { Box, Button, Typography } from '@mui/material';
// scss
import './EmailNotInSystem.scss';
import { useDispatch } from 'react-redux';
import { setCheckAccountSetupResult } from '../../../features/account-setup/accountSetupSlice';
import { ACCOUNT_SETUP_CODE } from '../../../features/account-setup/interface';
import { useAppSelector } from '../../../config/redux/hooks';

export const EmailNotInSystem = () => {
  const dispatch = useDispatch();

  const email = useAppSelector((state) => state.accountSetup.email);
  const mainTitle = `${email} not in system... Yet!`;

  const handleTryAnotherEmail = () => {
    dispatch(
      setCheckAccountSetupResult({
        email: '',
        code: ACCOUNT_SETUP_CODE.EMPTY,
      }),
    );
  };

  return (
    <Box className='email-not-in-system'>
      <Box className='email-not-in-system__container'>
        <Box className='email-not-in-system__container__title'>
          <Typography
            variant='h4'
            className='email-not-in-system__container__title__main'
          >
            {mainTitle}
          </Typography>
          <Typography
            variant='subtitle1'
            className='email-not-in-system__container__title__sub'
          >
            No worries, our support team will get you set up in no time– just
            give us a call!
          </Typography>
        </Box>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          type='button'
          onClick={handleTryAnotherEmail}
        >
          TRY ANOTHER EMAIL
        </Button>

        <Typography
          variant='h6'
          className='email-not-in-system__container__call_info'
        >
          Call us at (855) 425-8686
        </Typography>
      </Box>
    </Box>
  );
};

export default EmailNotInSystem;
