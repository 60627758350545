// Librariesimport { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';
import {
  getStoreUpdateSuccessToastProps,
  getStoreUpdateErrorToastProps,
} from '../../utils/toast/store-toast';

// Files
import { useUpdateStoreProps } from './interface';
import { updateStoreDialogValidationSchema } from './validations/updateStoreDialogValidationSchema';
import { useUpdateAccountSupplierMutation } from '../../../features/accountSupplier/accountSupplierSlice';
import { UpdateAccountSupplierFormData } from '../../../features/accountSupplier/interface';

const DEFAULT_VALUES = {
  account_number: '',
  sales_rep_name: '',
  sales_rep_phone: '',
  sales_rep_email: '',
};

export const useUpdateStore = ({
  id,
  name,
  defaultValues = DEFAULT_VALUES,
  onError = () => {},
  onSuccess = () => {},
}: useUpdateStoreProps) => {
  const methods = useForm({
    resolver: yupResolver(updateStoreDialogValidationSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit } = methods;
  const [updateAccountSupplier, { reset }] = useUpdateAccountSupplierMutation();

  const [showToast] = useExtendedSnackbar();

  const updateStore = handleSubmit((data: FieldValues) => {
    if (!id) return;
    const updateStoreFormData: UpdateAccountSupplierFormData = {
      id,
      data: {
        account_number: data.account_number,
        sales_rep_name: data.sales_rep_name,
        sales_rep_phone: data.sales_rep_phone,
        sales_rep_email: data.sales_rep_email,
      },
    };

    updateAccountSupplier(updateStoreFormData)
      .unwrap()
      .then((res) => {
        const storeUpdateSuccessToastProps = getStoreUpdateSuccessToastProps(
          res.name,
        );
        onSuccess();
        showToast(storeUpdateSuccessToastProps);
        reset();
      })
      .catch(() => {
        const storeUpdateErrorToastProps = getStoreUpdateErrorToastProps(name);
        onError();
        showToast(storeUpdateErrorToastProps);
      })
      .finally(() => {
        reset();
      });
  });

  return { updateStore, methods };
};
