// Libraries
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

// Components
import LoadingButton from '../../../LoadingButton/LoadingButton';

// Files
import { ReactComponent as TechnicianSearchIcon } from '../../../../../assets/icons/technician-search-icon.svg';

// Scss
import './CompanyConfirmTechsFormEmpty.scss';
import { Stack } from '@mui/material';
import { useGetCompanyAndServicesQuery } from '../../../../../features/company/companySlice';

const CompanyConfirmTechsFormEmpty = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  const { data } = useGetCompanyAndServicesQuery();

  return (
    <>
    <Box className='onboarding-step-form'>
      <Box className='company-confirm-techs-form-empty'>
        <Box className='company-confirm-techs-form-empty__box'>
          <Box className="className='company-confirm-techs-form-empty__icon'">
            <TechnicianSearchIcon />
          </Box>

          <Typography variant='button' color='inherit' className='pb-1 pt-2'>
            No techs have Connected with
          </Typography>

          <Typography variant='h4' color='inherit'>
            {data?.company?.data?.name}
          </Typography>

          <Typography variant='body2' color='inherit' className='pt-3'>
            You can invite your techs once you finish onboarding on the Teams
            page.
          </Typography>
        </Box>
      </Box>
    </Box>

      <Box className='onboarding-step-actions'>
        <Stack className='company-confirm-techs-form__footer' spacing={2}>
          <Box>
            <LoadingButton
              variant='contained'
              color='primary'
              size='medium'
              loading={isLoading}
              type='submit'
            >
              proceed
            </LoadingButton>
          </Box>
        </Stack>

    </Box>
    </>
  );
};

export default CompanyConfirmTechsFormEmpty;
