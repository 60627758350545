// Libraries
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

// Files
import { VisibilityAdornmentProps } from './interface';
import CapsLock from '../CapsLock/CapsLock';

const VisibilityAdornment = ({
  showText,
  setShowText,
}: VisibilityAdornmentProps) => {
  const handleShowPassword = () => {
    setShowText(!showText);
  };

  return (
    <InputAdornment position='end'>
      <CapsLock />

      <IconButton onClick={handleShowPassword}>
        {showText ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

export default VisibilityAdornment;
