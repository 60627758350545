import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { CheckValidTokenData, CheckValidTokenResponse } from './interface';

export const tokenApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    checkValidToken: build.query<CheckValidTokenResponse, CheckValidTokenData>({
      query: ({ token, email }: CheckValidTokenData) => {
        return {
          url: `/auth/check-token?token=${token}&email=${email}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useCheckValidTokenQuery, useLazyCheckValidTokenQuery } =
  tokenApi;
