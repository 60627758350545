// Libraries
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import persistSliceReducer from '../../features/persist/persistSlice';
import authReducer from '../../features/auth/authSlice';
import logoReducer from '../../features/logo/logoSlice';
import accountSetupReducer from '../../features/account-setup/accountSetupSlice';
import { axiosApi } from '../rtk/axiosApi/axiosApi';
import { reducer as niceModalReducer } from '@ebay/nice-modal-react';

// combiner reducers
const rootReducer = combineReducers({
  [axiosApi.reducerPath]: axiosApi.reducer,
  persist: persistSliceReducer,
  auth: authReducer,
  logo: logoReducer,
  accountSetup: accountSetupReducer,
  modals: niceModalReducer,
});

// setup redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['persist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// setup store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(axiosApi.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
