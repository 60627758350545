export function formatPhoneNumber(value: string | null) {
  if (!value) return '';
  // clean the input for any non-digit values and get the last 10 digits
  // We do that in order to clear any country codes that may be included

  const normalizedValue = value.replace(/[^\d]/g, '').slice(-10);
  const normalizedValueLength = normalizedValue.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (normalizedValueLength < 4) return normalizedValue;

  // if normalizedValueLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (normalizedValueLength < 7) {
    return `(${normalizedValue.slice(0, 3)}) ${normalizedValue.slice(3)}`;
  }

  // finally, if the normalizedValueLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${normalizedValue.slice(0, 3)}) ${normalizedValue.slice(
    3,
    6,
  )}-${normalizedValue.slice(6, 10)}`;
}

export function normalizePhoneNumber(value: string | null) {
  return value ? value.replace(/\D/g, '').slice(0, 10) : '';
}
