// Libraries
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';

// Components
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import PhoneFieldInput from '../PhoneFieldInput/PhoneFieldInput';

// FIles
import { US_STATES } from '../../constants/states';
import { AccountFormProps } from './interface';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UpdateCompanyFormValues } from '../../hooks/useUpdateCompanyForm/interface';

// Scss
import './AccountForm.scss';

const AccountForm = ({ isLoading, onDiscard }: AccountFormProps) => {
  const { control, formState, getFieldState } =
    useFormContext<UpdateCompanyFormValues>();
  const { error: serviceFieldsError } = getFieldState(
    'company_services',
    formState,
  );
  const { fields: serviceFields } = useFieldArray({
    control,
    name: 'company_services',
  });

  return (
    <Box className='account-form'>
      <Grid container>
        <Grid container item className='account-field-container'>
          <TextFieldInput
            fullWidth
            name='name'
            label='Company Name'
            className='account-field-input'
          />
          <TextFieldInput
            fullWidth
            name='number_of_techs'
            label='Number of Techs'
            className='account-field-input'
          />
          <TextFieldInput
            fullWidth
            name='years_in_business'
            label='Years in Business'
            className='account-field-input'
          />
        </Grid>

        <Grid container item className='account-field-container '>
          <PhoneFieldInput
            fullWidth
            name='phone'
            label='Phone Number'
            className='account-field-input'
          />
          <TextFieldInput
            fullWidth
            name='email'
            label='Email Address'
            className='account-field-input'
          />
        </Grid>
        <Grid container item className='account-field-container'>
          <TextFieldInput
            fullWidth
            name='address'
            label='Address 1'
            className='account-field-input'
          />
          <TextFieldInput
            fullWidth
            name='address_2'
            label='Address 2'
            className='account-field-input'
          />
        </Grid>
        <Grid container item className='account-field-container'>
          <TextFieldInput
            fullWidth
            name='city'
            label='City'
            className='account-field-input'
          />
          <Dropdown
            name='state'
            label='State'
            items={US_STATES}
            className='account-field-input'
          />
          <TextFieldInput
            fullWidth
            name='zip_code'
            label='Zip'
            className='account-field-input'
          />
        </Grid>
      </Grid>

      <FormControl
        className='account-company-services'
        name='services'
        component='fieldset'
        error={!!serviceFieldsError}
      >
        <Typography variant='h6'>Company Services</Typography>

        <FormGroup>
          {serviceFields.map((field, index) => (
            <FormControlLabel
              key={field.id}
              control={
                <Checkbox name={`company_services[${index}].default_checked`} />
              }
              label={
                <Typography variant='body1'>{field.service_name}</Typography>
              }
            />
          ))}
        </FormGroup>
        <FormHelperText
          className={
            serviceFieldsError ? '' : 'account-company-services-no-error'
          }
        >
          {serviceFieldsError?.message}
        </FormHelperText>
      </FormControl>

      <Grid container className='account-form-buttons'>
        <Button
          disabled={isLoading || !formState.isDirty}
          variant='outlined'
          onClick={onDiscard}
        >
          DISCARD CHANGES
        </Button>

        <Button
          disabled={isLoading || !formState.isValid || !formState.isDirty}
          variant='contained'
          type='submit'
        >
          {isLoading ? 'LOADING...' : 'SAVE CHANGES'}
        </Button>
      </Grid>
    </Box>
  );
};

export default AccountForm;
