// Libraries
import { Fragment, RefObject, useRef } from 'react';
import { Divider, Box } from '@mui/material';

// Components
import StoreSearchCard from '../StoreSearchCard/StoreSearchCard';
import StoreSearchEmpty from '../StoreSearchEmpty/StoreSearchEmpty';

// Files
import { StoreSearchCardListProps } from './interface';

// Scss
import './StoreSearchList.scss';

const StoreSearchCardList = ({
  data,
  searchValue,
  activeStore,
  onSearchCardClick,
}: StoreSearchCardListProps) => {
  const cardsListRef = useRef<RefObject<HTMLDivElement>>(null);
  const isEmpty = !data?.length;

  return isEmpty ? (
    <StoreSearchEmpty searchValue={searchValue} />
  ) : (
    <Box className='store-search-list' ref={cardsListRef}>
      {data.map((store) => (
        <Fragment key={store.id}>
          <StoreSearchCard
            data={store}
            onClick={onSearchCardClick}
            selected={store.id === activeStore?.id}
          />
          <Divider className='store-search-list-divider' />
        </Fragment>
      ))}
    </Box>
  );
};

export default StoreSearchCardList;
