// Libraries
import { Box, Grid, Typography } from '@mui/material';

// scss
import './LinkSent.scss';

const LinkSent = () => {
  return (
    <Box className='link-sent'>
      <Grid item xs={8}>
        <Typography className='link-sent-header' variant='h4'>
          Link Sent!
        </Typography>
        <Typography className='link-sent-subheader' variant='h6'>
          Please be sure to check all email folders in your email!
        </Typography>
      </Grid>
    </Box>
  );
};

export default LinkSent;
