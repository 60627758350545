// Libraries
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

// Components
import LoginFormHeader from '../../common/components/LoginFormHeader/LoginFormHeader';
import TextFieldInput from '../../common/components/TextFieldInput/TextFieldInput';
import ButtonLink from '../../common/components/ButtonLink/ButtonLink';

// Files
import { ForgotPasswordValidationSchema } from './validations/ForgotPasswordValidationSchema';
import { useForgotPasswordMutation } from '../../features/password/passwordSlice';
import { ForgotPasswordFormData } from '../../features/password/interface';

// scss
import './ForgotPassword.scss';
import LoadingButton from '../../common/components/LoadingButton/LoadingButton';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, forgotPasswordResponse] = useForgotPasswordMutation();
  const isLoading = useMemo(
    () => !!forgotPasswordResponse.isLoading,
    [forgotPasswordResponse],
  );

  useEffect(() => {
    const { isUninitialized, isSuccess } = forgotPasswordResponse;

    if (isUninitialized) {
      return;
    }

    if (isSuccess) {
      navigate('/link-sent');
    }
  }, [forgotPasswordResponse, navigate]);

  const initialValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordValidationSchema),
    defaultValues: initialValues,
    // mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleOnSubmit = ({ email }: FieldValues) => {
    const forgotPasswordFormData: ForgotPasswordFormData = {
      data: {
        email,
      },
    };

    forgotPassword(forgotPasswordFormData);
  };

  const handleGoBackToLogin = () => {
    navigate('/login');
  };

  return (
    <FormProvider {...methods}>
      <form className='forgot-password' onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid item xs={8}>
          <LoginFormHeader
            className='forgot-password-header'
            header='Reset Password'
            subheader='Please enter your email and we will send you a link to create a new password.'
            note='This will change your password for both Bluon for Contractors & the Bluon App.'
          />

          <TextFieldInput
            fullWidth
            variant='outlined'
            name='email'
            label='Email Address'
          />

          <LoadingButton
            className='forgot-password-forgot-button'
            type='submit'
            variant='contained'
            fullWidth
            disabled={!isValid}
            loading={isLoading}
          >
            Send Link
          </LoadingButton>

          <ButtonLink startIcon={<ArrowBack />} onClick={handleGoBackToLogin}>
            <Typography variant='body1'>Go Back to Login</Typography>
          </ButtonLink>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;
