// Files
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { Paginated } from './../../common/interfaces/paginated';
import { Supplier, getSupplierListFormData } from './interface';

const supplierApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierList: build.query<Paginated<Supplier>, getSupplierListFormData>({
      query: (params) => ({
        url: '/suppliers',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetSupplierListQuery } = supplierApi;
