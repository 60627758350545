import * as Yup from 'yup';
import { MAX_FILE_SIZE_3MB } from '../constants/constants';
import { allowedExtensionsRegExp } from '../constants/regExp';

export const logoValidationSchema = Yup.mixed()
  .test(
    'file-size',
    'Your file is too large. Max size of 3MB.',
    (file: File) => {
      if (!(file instanceof File)) return true;

      return file?.size < MAX_FILE_SIZE_3MB;
    },
  )
  .test('file-extension', 'The file extension is not allowed', (file: File) => {
    if (!(file instanceof File)) return true;

    return !!allowedExtensionsRegExp.exec(file.name);
  });
