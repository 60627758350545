// Libraries
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import NavBar from '../../common/components/NavBar/NavBar';
import { useAppSelector } from '../../config/redux/hooks';

// Files
import { PrivateRoutesProps } from './interface';
import useServerSnackbar from '../../common/hooks/useServerSnackbar/useServerSnackbar';
import UpcomingFeatureModal from '../../common/components/UpcomingFeatureModal/UpcomingFeatureModal';

// Scss
import './PrivateRoutes.scss';
import { show as showNiceModal } from '@ebay/nice-modal-react';
import { useEffect } from 'react';

const PrivateRoutes = ({ keepHeader = true }: PrivateRoutesProps) => {
  const { pathname, state } = useLocation();
  const persist = useAppSelector((state) => state.persist);
  const isUserLoggedIn = persist.token && persist.userData;

  useServerSnackbar();

  useEffect(() => {
    if (!state?.showUpcomingFeatureModal || !isUserLoggedIn) return;

    const timeout = setTimeout(() => {
      showNiceModal('upcoming-orders-page');
      window.history.replaceState({}, document.title);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [state, pathname, isUserLoggedIn]);

  if (!isUserLoggedIn) {
    return <Navigate to='/login' />;
  }

  if (pathname !== '/onboarding' && !persist.userData?.verifiedAt) {
    return <Navigate to='/onboarding' />;
  }

  if (pathname === '/onboarding' && persist.userData?.verifiedAt) {
    return <Navigate to='/technicians' />;
  }

  return (
    <>
      {keepHeader && <NavBar />}

      <Outlet />

      <UpcomingFeatureModal
        id='upcoming-orders-page'
        title='Coming June 2023!'
        subTitle='See & Manage Part Orders'
        imageSrc={require('../../assets/images/order-upcoming.png')}
      />
    </>
  );
};

export default PrivateRoutes;
