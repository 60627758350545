// Components
import CompanyTermsForm from '../../../../common/components/CompanyAcknowledgeForm/CompanyTermsForm';

// Files
import { OnboardingStepComponentProps } from '../../interface';

const OnboardingStepOne = ({ onNextPage }: OnboardingStepComponentProps) => {
  return (
    <CompanyTermsForm onSuccess={onNextPage} />
  );
};

export default OnboardingStepOne;
