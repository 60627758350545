import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthLoginAttemptState, AuthState } from './interface';

const initialState: AuthState = {
  loginAttempt: 10,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginAttempt: (
      state,
      { payload: { loginAttempt } }: PayloadAction<AuthLoginAttemptState>,
    ) => {
      state.loginAttempt = loginAttempt;
    },
    resetLoginAttempt: (state) => {
      state.loginAttempt = initialState.loginAttempt;
    },
  },
});

export const { setLoginAttempt, resetLoginAttempt } = authSlice.actions;

export default authSlice.reducer;
