// Libraries
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import LoginFormHeader from '../../common/components/LoginFormHeader/LoginFormHeader';
import TextFieldInput from '../../common/components/TextFieldInput/TextFieldInput';

// scss
import './VerifyAccount.scss';
const VerifyAccount = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form className='verify-account'>
        <Grid item xs={8}>
          <LoginFormHeader
            className='verify-account-header'
            header='Verify Bluon Account'
            subheader='Verify that this is your account.'
          />

          <Box className='verify-account-row'>
            <TextFieldInput
              fullWidth
              className='verify-account-input-field'
              name='first-name'
              label='First name *'
            />
            <TextFieldInput
              fullWidth
              className='verify-account-input-field'
              name='last-name'
              label='Last name *'
            />
          </Box>

          <TextFieldInput
            className='verify-account-email'
            fullWidth
            name='email'
            label='Email *'
          />

          <Box className='verify-account-row'>
            <TextFieldInput
              fullWidth
              className='verify-account-input-field'
              name='phone-number'
              label='Phone number *'
            />
            <TextFieldInput
              fullWidth
              className='verify-account-input-field'
              name='zip-code'
              label='Zip code *'
            />
          </Box>

          <Box className='verify-account-radio'>
            <Typography className='verify-account-radio-text' variant='body1'>
              How would you like to receive your two-factor authentification
              code?
            </Typography>
            <RadioGroup className='verify-account-radio-group'>
              <FormControlLabel
                className='verify-account-radio-buttons'
                value='phoneNumber'
                control={<Radio />}
                label='Phone Number'
              />
              <FormControlLabel
                className='verify-account-radio-buttons'
                value='email'
                control={<Radio />}
                label='Email'
              />
            </RadioGroup>
          </Box>

          <Box className='verify-account-buttons'>
            <Button
              className='verify-account-buttons-not-me'
              variant='outlined'
            >
              {"That's not me..."}
            </Button>
            <Button
              className='verify-account-buttons-its-me'
              variant='contained'
            >
              {"That's Me! Send Code"}
            </Button>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default VerifyAccount;
