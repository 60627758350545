// Libraries
import { useNavigate } from 'react-router-dom';

// Files
import { useAppDispatch } from '../../../config/redux/hooks';
import {
  setCredentials,
  setUserData,
} from '../../../features/persist/persistSlice';
import {
  AuthPersistCredentialState,
  setUserDataPayload,
} from '../../../features/persist/interface';
import {
  getBrokenURLErrorToastProps,
  getPasswordSuccessToastProps,
} from '../../utils/toast/toast';
import { useCreatePasswordMutation } from '../../../features/password/passwordSlice';
import { CreatePasswordFormData } from '../../../features/password/interface';
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';
import { useLazyGetCompanyQuery } from '../../../features/company/companySlice';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useCreatePassword = (): [
  (formData: CreatePasswordFormData) => void,
  any,
] => {
  const [createPassword, createPasswordResponse] = useCreatePasswordMutation();
  const [getCompanyInfo] = useLazyGetCompanyQuery();
  const [showToast] = useExtendedSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createPasswordWithToasts = async (formData: CreatePasswordFormData) => {
    await createPassword(formData)
      .unwrap()
      .then(({ id, token }) => {
        const passwordSuccessToastProps = getPasswordSuccessToastProps();
        showToast(passwordSuccessToastProps);

        const authPersistPayload: AuthPersistCredentialState = {
          userId: id,
          token: token,
        };
        dispatch(setCredentials(authPersistPayload));

        return getCompanyInfo().unwrap();
      })
      .then((res) => {
        const payload: setUserDataPayload = {
          verifiedAt: res.verified_at,
        };

        dispatch(setUserData(payload));
        navigate('/technicians');
      })
      .catch(() => {
        const passwordErrorToastProps = getBrokenURLErrorToastProps();
        showToast(passwordErrorToastProps);
      });
  };

  return [createPasswordWithToasts, createPasswordResponse];
};
