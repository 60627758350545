import { UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Files
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';
import { companyFormValidationSchema } from './validations/companyFormValidationSchema';
import { useUpdateCompanyMutation } from '../../../features/company/companySlice';
import {
  UpdateCompanyFormValues,
  UseUpdateCompanyFormProps,
} from './interface';

import {
  normalizeUpdateCompanyFormInputData,
  normalizeUpdateCompanyFormOutputData,
} from './utils/normalize';
import {
  getAccountUpdateErrorToastProps,
  getAccountUpdateSuccessToastProps,
} from '../../utils/toast/toast';

const useUpdateCompanyForm = ({
  data,
  onSuccess = () => {},
}: UseUpdateCompanyFormProps): [
  UseFormReturn<UpdateCompanyFormValues>,
  () => void,
  {
    isLoading: boolean;
  },
] => {
  const [showToast] = useExtendedSnackbar();
  const [updateCompanyFn, updateCompanyResponse] = useUpdateCompanyMutation();
  normalizeUpdateCompanyFormInputData(data.company.data, data.services.data);
  const defaultValues = normalizeUpdateCompanyFormInputData(
    data.company.data,
    data.services.data,
  );

  const methods = useForm<UpdateCompanyFormValues>({
    resolver: yupResolver(companyFormValidationSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const { reset } = methods;

  const updateCompany = methods.handleSubmit((formData) => {
    const normalizedFormData = normalizeUpdateCompanyFormOutputData(formData);

    updateCompanyFn(normalizedFormData)
      .unwrap()
      .then((res) => {
        const accountUpdateSuccessToastProps =
          getAccountUpdateSuccessToastProps();
        showToast(accountUpdateSuccessToastProps);

        onSuccess();

        // Reset Values so the form is no longer dirty
        reset(
          normalizeUpdateCompanyFormInputData(res.data, data.services.data),
        );
      })
      .catch(() => {
        const accountUpdateErrorToastProps = getAccountUpdateErrorToastProps();
        showToast(accountUpdateErrorToastProps);
      });
  });

  return [
    methods,
    updateCompany,
    {
      isLoading: updateCompanyResponse.isLoading,
    },
  ];
};

export default useUpdateCompanyForm;
