import * as Yup from 'yup';
import {
  INVALID_PASSWORD,
  REQUIRED_PASSWORD,
} from '../../../common/constants/validationStrings';

export const CreatePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED_PASSWORD)
    .min(8, INVALID_PASSWORD)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, INVALID_PASSWORD),

  password_confirmation: Yup.string()
    .required(REQUIRED_PASSWORD)
    .oneOf([Yup.ref('password'), null], 'Passwords must match.'),

  agree_to_terms: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});
