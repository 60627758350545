// Libraries
import cx from 'classnames';
import { Drawer, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

// Files
import { StyledDrawerProps } from './interface';

// Scss
import './StyledDrawer.scss';

const StyledDrawer = ({
  open,
  onClose,
  children,
  className,
  closeIconClassName = '',
  ...rest
}: StyledDrawerProps) => {
  return (
    <Drawer
      className={cx('styled-drawer', className)}
      onClose={onClose}
      open={open}
      {...rest}
    >
      {onClose ? (
        <IconButton
          aria-label='Close Modal'
          size='medium'
          className={`styled-drawer__close${closeIconClassName}`}
          onClick={onClose}
        >
          <CancelIcon fontSize='inherit' />
        </IconButton>
      ) : null}

      {children}
    </Drawer>
  );
};

export default StyledDrawer;
