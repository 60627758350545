// Libraries
import Rating from '@mui/material/Rating';
import BuildIcon from '@mui/icons-material/Build';

// Files
import { TechSkillLevelProps } from './interface';

// Scss
import './TechSkillLevel.scss';

const TechSkillLevel = ({
  size = 'small',
  value = 0,
  max = 3,
  ...props
}: TechSkillLevelProps) => {
  return (
    <Rating
      size={size}
      className='tech-skill-level'
      icon={
        <BuildIcon
          className='tech-skill-level__icon is-active'
          fontSize='inherit'
        />
      }
      emptyIcon={
        <BuildIcon className='tech-skill-level__icon' fontSize='inherit' />
      }
      value={value}
      max={max}
      {...props}
    />
  );
};

export default TechSkillLevel;
