import { Box, Button, FormHelperText, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginLoader } from '../../common/components/LoginLoaderProvider/LoginLoaderProvider';
import { ForgotPasswordFormData } from '../../features/password/interface';
import { useForgotPasswordMutation } from '../../features/password/passwordSlice';

import './LinkExpired.scss';

const LinkExpired = () => {
  const [forgotPassword, forgotPasswordResponse] = useForgotPasswordMutation();
  const [searchParams] = useSearchParams();
  const { showLoader } = useLoginLoader();
  const navigate = useNavigate();

  useEffect(() => {
    const { isUninitialized, isSuccess, isLoading } = forgotPasswordResponse;
    if (isUninitialized) {
      return;
    }

    if (isLoading) {
      showLoader(true);

      return;
    }
    showLoader(false);

    if (isSuccess) {
      showLoader(false);
      navigate('/link-sent');
    }
  }, [forgotPasswordResponse, navigate, showLoader]);

  const handleResendClick = () => {
    const email = searchParams.get('email');
    if (!email) {
      return;
    }

    const forgotPasswordFormData: ForgotPasswordFormData = {
      data: {
        email,
      },
    };

    forgotPassword(forgotPasswordFormData);
  };

  return (
    <Box className='link-expired'>
      <Grid item xs={8}>
        <Typography className='link-expired-header' variant='h4'>
          Link Expired
        </Typography>
        <FormHelperText error className='link-expired-warning-text'>
          This link has expired. <br /> Please click the button below to send a
          new link.{' '}
        </FormHelperText>
        <Button fullWidth variant='contained' onClick={handleResendClick}>
          Resend Link
        </Button>
      </Grid>
    </Box>
  );
};

export default LinkExpired;
