// Libraries
import { Fragment } from 'react';
import { Box, Card, CardHeader, Typography } from '@mui/material';

// Files
import { StoreDetailsRepCardProps } from './interface';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';

// Scss
import './StoreDetailsRepCard.scss';

const StoreDetailsRepCard = ({
  title,
  email,
  phone,
  name,
}: StoreDetailsRepCardProps) => {
  const showSalesRep = email || phone || name;

  if (!showSalesRep) return null;

  return (
    <Box className='store-details-rep-card'>
      <Typography className='store-details-rep-card__title' variant='body1'>
        {title}
      </Typography>

      <Card elevation={2}>
        <CardHeader
          title={name}
          titleTypographyProps={{
            variant: 'body1',
          }}
          subheader={
            phone || email ? (
              <Fragment>
                {phone && (
                  <Typography variant='body2'>
                    {'Phone: '}
                    {formatPhoneNumber(phone)}
                  </Typography>
                )}

                {email && (
                  <Typography variant='body2'>
                    {'Email: '}
                    {email}
                  </Typography>
                )}
              </Fragment>
            ) : null
          }
        />
      </Card>
    </Box>
  );
};

export default StoreDetailsRepCard;
