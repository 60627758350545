import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useAccountSetupMutation } from '../../../features/account-setup/accountSetupApi';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import { FinishAccountSetupSchema } from './validations/AccountSetupSchema';
// scss
import './AccountSetupForm.scss';
import { setCheckAccountSetupResult } from '../../../features/account-setup/accountSetupSlice';
import { useAppDispatch } from '../../../config/redux/hooks';
import { ACCOUNT_SETUP_CODE } from '../../../features/account-setup/interface';
import { MAIL_ALREADY_UP, MAIL_NOT_EXIST } from './contants';

const AccountSetupForm = () => {
  // Mutation
  const [accountSetup, accountSetupResponse] = useAccountSetupMutation();
  const dispatch = useAppDispatch();
  // Validate Form
  const initialValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(FinishAccountSetupSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const { handleSubmit, watch } = methods;
  const emailValue = watch('email');

  // On Submit
  const isLoading = useMemo(
    () => !!accountSetupResponse?.isLoading,
    [accountSetupResponse],
  );

  // Check response from mutation
  useEffect(() => {
    const { isUninitialized, isError, isSuccess, error } = accountSetupResponse;

    if (isUninitialized) return;

    if (isError) {
      const mappedError = (error as any)?.data?.errors?.email[0];

      const notExist = mappedError === MAIL_NOT_EXIST;
      const alreadySetUp = mappedError === MAIL_ALREADY_UP;

      if (notExist || alreadySetUp)
        dispatch(
          setCheckAccountSetupResult({
            email: emailValue,
            code: notExist
              ? ACCOUNT_SETUP_CODE.NOT_EXISTS
              : ACCOUNT_SETUP_CODE.ALREADY_SETUP,
          }),
        );
    }
    if (isSuccess) {
      dispatch(
        setCheckAccountSetupResult({
          email: emailValue,
          code: ACCOUNT_SETUP_CODE.SUCCESS,
        }),
      );
    }
  }, [accountSetupResponse]);

  // Handle Submit form
  const handleOnSubmit = async ({ email }: FieldValues) => {
    const formData = {
      email: email,
    };
    accountSetup(formData);
  };

  return (
    <FormProvider {...methods}>
      <form
        className='finish-account-setup'
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Grid item xs={8}>
          <Box className='finish-account-setup__title'>
            <Typography
              variant='h4'
              className='finish-account-setup__title__main'
            >
              Welcome!
            </Typography>
            <Typography
              variant='subtitle1'
              className='finish-account-setup__title__sub'
            >
              Please enter your email address to confirm you are in our system.
            </Typography>
          </Box>

          <TextFieldInput
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            className='finish-account-setup__email__field'
            variant='outlined'
            label='Email Address'
            name='email'
          />

          <LoadingButton
            fullWidth
            variant='contained'
            size='large'
            color='primary'
            type='submit'
            disabled={!emailValue}
            loading={isLoading}
          >
            CONTINUE
          </LoadingButton>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AccountSetupForm;
