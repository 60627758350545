// TODO Delete next line once actions and alerts are visible
/* eslint-disable @typescript-eslint/no-unused-vars */

// Libraries
import { useState } from 'react';
import {
  Alert,
  Card,
  CardActions,
  CardHeader,
  Avatar,
  Button,
  Tooltip,
  Chip,
  Link,
  CardActionArea,
} from '@mui/material';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DraftsIcon from '@mui/icons-material/Drafts';

// FIles
import { TechnicianCardInterface } from './interface';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';
import { useNavigate } from 'react-router-dom';
import TechnicianDetailsCard from '../TechnicianDetailsCard';
import StyledDrawer from '../StyledDrawer/StyledDrawer';

// Scss
import './TechnicianCard.scss';

export default function TechnicianCard({
  data,
  severity = 'info',
}: TechnicianCardInterface) {
  const navigate = useNavigate();
  const { id, name, phone, image, is_new: isNew } = data;

  const [showDetail, setShowDetail] = useState<boolean>(false);

  const handleClick = () => {
    setShowDetail(true);
  };

  const getInitials = (str: string | null) => {
    if (!str) return '';
    const splittedString = str.split(' ');

    const firstName = splittedString[0] ? splittedString[0][0] : '';
    const lastName = splittedString[1] ? splittedString[1][0] : '';

    return firstName + lastName;
  };

  const renderDetail = () => {
    return (
      <StyledDrawer
        anchor='right'
        open={showDetail}
        onClose={() => setShowDetail(false)}
        closeIconClassName=' technician-card-detail-close-button'
      >
        <TechnicianDetailsCard id={`${id}`} />
      </StyledDrawer>
    );
  };

  return (
    <>
      {renderDetail()}
      <Card elevation={1} className='technician-card' onClick={handleClick}>
        <CardActionArea>
          <CardHeader
            className='technician-card__header'
            action={
              isNew && (
                <Chip
                  label='New'
                  variant='outlined'
                  color='success'
                  size='small'
                />
              )
            }
            avatar={
              <Avatar src={image?.url} alt={`Picture of ${name}`}>
                {getInitials(name)}
              </Avatar>
            }
            title={name}
            subheader={phone && formatPhoneNumber(phone)}
          />

          {/* <CardActions disableSpacing>
        <CardActions>
          <Button size='small' startIcon={<PlayCircleOutlineIcon />}>
            Video call
          </Button>

          <Tooltip
            title='Coming Soon'
            placement='right'
            arrow
            classes={{
              tooltip: 'technician-card__tooltip',
            }}
          >
            <Button size='small' startIcon={<DraftsIcon />}>
              Chat
            </Button>
          </Tooltip>
        </CardActions>
      </CardActions> */}
          {/* <Alert
        className='technician-card__alert'
        icon={false}
        severity={severity}
      >
        3 item(s) need attention
      </Alert> */}
        </CardActionArea>
      </Card>
    </>
  );
}
