import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { axiosApi } from '../../../config/rtk/axiosApi/axiosApi';
import { AuthPersistCredentialState } from '../../../features/persist/interface';
import { setCredentials } from '../../../features/persist/persistSlice';
import { DropdownRoutes } from '../NavBar/NavBar';

import { NavDropDownProps } from './interface';

import './styles.scss';

export const NavDropDown = ({ logo }: NavDropDownProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dropdownMenu, setDropDownMenu] = useState<null | HTMLElement>(null);

  const handleCloseUserMenu = () => {
    setDropDownMenu(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setDropDownMenu(event.currentTarget);
  };

  const logoutAction = (title: string) => {
    if (title === 'Log Out') {
      const logoutCredentials: AuthPersistCredentialState = {
        userId: '',
        token: '',
      };

      dispatch(setCredentials(logoutCredentials));
      dispatch(axiosApi.util.resetApiState());
      navigate('/login');
    }
  };

  return (
    <Grid item xs className='container-dropdown'>
      <img src={logo} className='dropdown-image' />
      <IconButton onClick={handleOpenUserMenu}>
        <ArrowDropDownIcon fontSize='large' />
      </IconButton>
      <Menu
        PaperProps={{ sx: { width: '16.25rem' } }}
        MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
        anchorEl={dropdownMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={Boolean(dropdownMenu)}
        onClose={handleCloseUserMenu}
      >
        {DropdownRoutes.map((route) => {
          return (
            <NavLink
              key={route.title}
              to={route.path}
              onClick={() => logoutAction(route.title)}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText>{route.title}</ListItemText>
              </MenuItem>
            </NavLink>
          );
        })}
      </Menu>
    </Grid>
  );
};
