import * as Yup from 'yup';
import {
  INVALID_EMAIL,
  // INVALID_PASSWORD,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD,
} from '../../../constants/validationStrings';

// export const LoginValidationSchema = Yup.object().shape({
//   email: Yup.string().required(REQUIRED_EMAIL).email(INVALID_EMAIL),
//   password: Yup.string()
//     .required(REQUIRED_PASSWORD)
//     .min(8, INVALID_PASSWORD)
//     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, INVALID_PASSWORD),
// });

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().required(REQUIRED_EMAIL).email(INVALID_EMAIL),
  password: Yup.string().required(REQUIRED_PASSWORD),
});
