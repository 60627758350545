// Libraries
import { Button, CircularProgress } from '@mui/material';

// Files
import { LoadingButtonProps } from './interface';

// scss
import './LoadingButton.scss';

const LoadingButton = ({
  children,
  loading,
  disabled,
  ...rest
}: LoadingButtonProps) => {
  return (
    <Button {...rest} disabled={disabled || loading}>
      {children}
      {loading ? (
        <CircularProgress color='inherit' size={12} sx={{ ml: 1 }} />
      ) : (
        <></>
      )}
    </Button>
  );
};

export default LoadingButton;
