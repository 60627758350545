// Libraries
import { Container, Grid, Typography } from '@mui/material';

// Components
import {
  StateHandlerError,
  StateHandlerLoading,
} from '../../common/components/StateHandler/StateHandler';
import CompanyUpdateForm from '../../common/components/CompanyUpdateForm/CompanyUpdateForm';

// Files
import { useGetCompanyAndServicesQuery } from '../../features/company/companySlice';

// Scss
import './Account.scss';

const Account = () => {
  const { data, isLoading } = useGetCompanyAndServicesQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return (
    <Container className='account' maxWidth={'lg'}>
      <Typography variant='h5' className='account-header'>
        Your Account
      </Typography>
      <Grid container item className='account-page'>
        <CompanyUpdateForm data={data} />
      </Grid>
    </Container>
  );
};

export default Account;
