import { CircularProgress } from '@mui/material';
import { LoginLoaderProps } from './interface';

import './LoginLoader.scss';

const LoginLoader = ({ isLoading }: LoginLoaderProps) => {
  return (
    <>
      {isLoading && (
        <div className='login-loader'>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default LoginLoader;
