// Libraries
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

// Components
import StyledDialog from '../StyledDialog/StyledDialog';

// Files
import { ConfirmationDialogProps } from './interface';

// Scss
import './ConfirmationDialog.scss';

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    title,
    content,
    color = 'error',
    cancelButtonText = 'Cancel',
    discardButtonText = 'Discard',
    confirmButtonText = 'Confirm',
    showDiscardButton = false,
    disableConfirmBtn = false,
    onClose = () => {},
    onDiscard = () => {},
    onConfirm = () => {},
    ...rest
  } = props;

  const handleOnDiscard = () => {
    onClose();
    onDiscard();
  };

  const handleOnConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <StyledDialog className='confirmation-dialog' onClose={onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText color='inherit'>{content}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions className='confirmation-dialog__actions'>
        <Button variant='outlined' onClick={onClose}>
          {cancelButtonText}
        </Button>

        {showDiscardButton && (
          <Button
            className='confirmation-dialog__discard-btn'
            variant='outlined'
            onClick={handleOnDiscard}
          >
            {discardButtonText}
          </Button>
        )}

        <Button
          variant='contained'
          autoFocus
          color={color}
          disabled={disableConfirmBtn}
          onClick={handleOnConfirm}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
