import { Button as ButtonMui, CircularProgress } from '@mui/material';
import classNames from 'classnames';

import { RoundedButtonProps } from './interface';
import './styles.scss';

const RoundedButton = ({
  color,
  disabled,
  children,
  loading,
  'data-testid': dataTestId,
  ...props
}: RoundedButtonProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'flex h-8 items-center rounded-2xl  border  shadow-md',
        {
          'bg-[var(--bluon--default--blue-3)]':
            color === 'primary' && !disabled,
          'bg-[var(--bluon--white)]': color === 'secondary' && !disabled,
          'bg-[var(--bluon--ds--lightgray-3)]':
            color === 'neutral' && !disabled,
          'bg-[var(--bluon--light--gray-12)]': disabled,
        },
      )}
    >
      <ButtonMui
        {...props}
        disabled={disabled}
        data-testid={dataTestId}
        variant='text'
        className='rounder-button'
        color={
          color === 'neutral'
            ? 'neutral'
            : color !== 'secondary'
            ? 'primaryLight'
            : 'primary'
        }
      >
        {loading ? (
          <CircularProgress
            size='small'
            className='bl-button-loader'
            data-testid={dataTestId}
          />
        ) : (
          children
        )}
      </ButtonMui>
    </div>
  );
};

export default RoundedButton;
