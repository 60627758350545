import { createTheme } from '@mui/material';

const themePalette = createTheme({
  palette: {
    action: {
      disabledBackground: '',
    },
    primary: {
      main: '#0567D0',
      contrastText: '#FFFFFF',
    },
    primaryLight: {
      main: '#FFF',
      contrastText: '#FFFFFF',
    },
    primaryDark: {
      main: '#004596',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#304050',
    },
    success: {
      main: '#16A64E',
      contrastText: '#FFFFFF',
    },
    successLight: {
      main: '#00B246',
      contrastText: '#FFFFFF',
    },
    successDark: {
      main: '#068B3A',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF8765',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
    error: {
      main: '#DD3217',
    },
    neutral: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    gray: {
      main: '#bcbcbc',
      dark: '#979797',
    },
  },
});

export const BluonTheme = createTheme(themePalette, {
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#EFEFEF',
          height: '44px',
          border: '1px solid transparent',
          borderRadius: '3px',
          padding: '0px 16px',
          '&.Mui-error': {
            border: `1px solid ${themePalette.palette.warning.main}`,
          },
          '&.Mui-focused': {
            backgroundColor: themePalette.palette.background.paper,
            border: '1px solid #94A8C5',
          },
          '&.Mui-disabled': {
            opacity: '0.32',
          },
          '&:hover': {
            border: '1px solid #D6D9DC',
          },
        },
        input: {
          '&::placeholder': {
            fontStyle: 'italic',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '0.8125rem',
          lineHeight: '20px',
          color: themePalette.palette.warning.main,
          marginTop: '4px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1rem',
          lineHeight: '28px',
          color: '#cbcbcb',
          marginBottom: '4px',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontSize: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1rem',
          lineHeight: '28px',
          marginBottom: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-gutters': {
            backgroundColor: themePalette.palette.background.default,
            borderBottom: '1px solid #EAEDF3',
            height: '48px',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(5, 103, 208, 0.08)',
          },
          '&:hover': {
            backgroundColor: 'rgba(5, 103, 208, 0.12)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          fontWeight: 500,
          fontSize: '0.875rem',
          '&.MuiSvgIcon-root': {
            color: '#FFFFFF',
          },
          '&.MuiButtonBase-root.MuiButton-contained.Mui-disabled': {
            color: '#424242',
            backgroundColor: '#e9e9e9',
            opacity: 0.35,
          },
          '&.MuiButton-sizeLarge': {
            padding: '12.5px 70px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '2rem',
        },
        h5: {
          fontSize: '1.875rem',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: '2rem',
          [themePalette.breakpoints.up('md')]: {
            paddingLeft: '4.0625em',
            paddingRight: '4.0625em',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&.MuiChip-filled': {
            '.MuiChip-deleteIcon': {
              color: '#FFFFFF',
            },
          },
          '.MuiChip-deleteIconSmall': {
            fontSize: '1.2rem',
          },
          '.MuiChip-deleteIconMedium': {
            fontSize: '1.375rem',
          },
          '.MuiChip-label': {
            fontWeight: '500',
          },
          '&.MuiChip-filled.MuiChip-label': {
            color: '#FFFFFF',
          },
          '.MuiChip-labelSmall': {
            fontSize: '0.875rem',
          },
          '.MuiChip-labelMedium': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000',
          '&:hover': {
            color: '#979797',
            backgroundColor: '#FFF',
          },
        },
      },
    },
  },
});

// Create new colors
declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
    successLight: Palette['primary'];
    successDark: Palette['primary'];
    neutral: Palette['primary'];
    gray: Palette['primary'];
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions['primary'];
    primaryDark: PaletteOptions['primary'];
    successLight: PaletteOptions['primary'];
    successDark: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
    primaryDark: true;
    successLight: true;
    successDark: true;
    neutral: true;
    gray: true;
  }
}
