// Libraries

import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Components
import App from './app/App';
import ExtendedSnackbarProvider from './common/components/ExtendedSnackbarProvider/ExtendedSnackbarProvider';
import { BluonTheme } from './config/mui/MuiTheme';

// Files
import { persistor, store } from './config/redux/store';
import NiceModal from '@ebay/nice-modal-react';

// scss
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
const theme = unstable_createMuiStrictModeTheme(BluonTheme);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate loading={null} persistor={persistor}>
        <NiceModal.Provider>
          <ExtendedSnackbarProvider>
            <App />
          </ExtendedSnackbarProvider>
        </NiceModal.Provider>
      </PersistGate>
    </ThemeProvider>
  </Provider>,
);
