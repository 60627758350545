// Libraries
import * as Yup from 'yup';

// Files
import { INVALID_EMAIL } from '../../../constants/validationStrings';

const SALES_REP_PHONE_TOO_SHORT =
  'The sales rep phone must be at least 7 characters.';
const SALES_REP_PHONE_TOO_LONG =
  'The sales rep phone may not be greater than 15 characters.';
const ACCOUNT_NUMBER_TO0_LONG =
  'The account number may not be greater than 50 characters.';
const SALES_REP_NAME_TO0_LONG =
  'The sales rep name may not be greater than 50 characters.';

export const updateStoreDialogValidationSchema = Yup.object().shape({
  account_number: Yup.string().max(50, ACCOUNT_NUMBER_TO0_LONG),
  sales_rep_name: Yup.string().max(50, SALES_REP_NAME_TO0_LONG),
  sales_rep_phone: Yup.string()
    .matches(/.{7,}/, {
      excludeEmptyString: true,
      message: SALES_REP_PHONE_TOO_SHORT,
    })
    .max(15, SALES_REP_PHONE_TOO_LONG),
  sales_rep_email: Yup.string().email(INVALID_EMAIL).max(255),
});
