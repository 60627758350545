import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// scss
import './AccountAlreadySetup.scss';
import { useDispatch } from 'react-redux';
import { setCheckAccountSetupResult } from '../../../features/account-setup/accountSetupSlice';
import { ACCOUNT_SETUP_CODE } from '../../../features/account-setup/interface';

const AccountAlreadySetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleResetPassword = () => {
    dispatch(
      setCheckAccountSetupResult({
        email: '',
        code: ACCOUNT_SETUP_CODE.EMPTY,
      }),
    );
    navigate('/forgot-password');
  };

  return (
    <Box className='account-already-setup'>
      <Box className='account-already-setup__container'>
        <Box className='account-already-setup__container__title'>
          <Box>
            <Typography
              variant='h4'
              className='account-already-setup__container__title__main'
            >
              This account has already been set up.
            </Typography>
            <Typography
              variant='h4'
              className='account-already-setup__container__title__main'
            >
              Would you like to reset your password?
            </Typography>
          </Box>
          <Typography
            variant='subtitle1'
            className='account-already-setup__container__title__sub'
          >
            If this doesn’t sound right, contact our support team any time!
          </Typography>
        </Box>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          type='button'
          onClick={handleResetPassword}
        >
          RESET PASSWORD
        </Button>

        <Typography
          variant='h6'
          className='account-already-setup__container__call_info'
        >
          Call us at (855) 425-8686
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountAlreadySetup;
