// Libraries
import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';

// Files
import { CompanyConfirmTechsFormChipProps } from './interface';

const CompanyConfirmTechsFormChip = ({
  name,
  index,
}: CompanyConfirmTechsFormChipProps) => {
  const { control } = useFormContext();
  const value = useWatch({
    name,
    control,
  });

  const { update } = useFieldArray({
    control,
    name: 'technicians',
  });

  const handleClick = () => {
    update(index, {
      ...value?.[index],
      confirmed: !value?.[index]?.confirmed,
    });
  };

  const composeFullName = `${value?.[index].user.first_name} ${value?.[index].user.last_name}`;

  return (
    <Chip
      icon={
        value?.[index]?.confirmed ? (
          <CheckCircleIcon color='primary' sx={{ fontSize: 20 }} />
        ) : (
          <AddIcon color='primary' sx={{ fontSize: 20 }} />
        )
      }
      label={composeFullName}
      color={value?.[index]?.confirmed ? 'default' : 'primary'}
      variant={value?.[index]?.confirmed ? 'filled' : 'outlined'}
      onClick={handleClick}
    />
  );
};

export default CompanyConfirmTechsFormChip;
