// Files
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { Paginated } from './../../common/interfaces/paginated';
import {
  AccountSupplier,
  GetAccountSupplierListFormData,
  UpdateAccountSupplierFormData,
} from './interface';

const accountSupplierApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getAccountSupplierList: build.query<
      Paginated<AccountSupplier>,
      GetAccountSupplierListFormData
    >({
      query: (params) => ({
        url: '/account/suppliers',
        method: 'GET',
        params,
      }),
      providesTags: (result) => {
        return result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.data.map(({ id }) => ({
                type: 'Account Supplier' as const,
                id,
              })),
              { type: 'Account Supplier', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Account Supplier', id: 'PARTIAL-LIST' }];
      },
    }),
    getAccountSupplierDetails: build.query<AccountSupplier, string>({
      query: (id) => ({
        url: `/account/suppliers/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Account Supplier', id: result.id }] : [],
      transformResponse: (response: { data: AccountSupplier }) => response.data,
    }),
    addAccountSupplier: build.mutation<AccountSupplier, string>({
      query: (supplier) => {
        return {
          url: '/account/suppliers',
          method: 'POST',
          data: {
            supplier,
          },
        };
      },
      invalidatesTags: [
        { type: 'Account Supplier', id: 'PARTIAL-LIST' },
        { type: 'Supplier', id: 'PARTIAL-LIST' },
      ],
      transformResponse: (response: { data: AccountSupplier }) => response.data,
    }),
    updateAccountSupplier: build.mutation<
      AccountSupplier,
      UpdateAccountSupplierFormData
    >({
      query: ({ id, data }: UpdateAccountSupplierFormData) => {
        return {
          url: `/account/suppliers/${id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (result) => {
        return result && result.id
          ? [
              {
                type: 'Account Supplier' as const,
                id: result.id,
              },
              { type: 'Account Supplier', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Account Supplier', id: 'PARTIAL-LIST' }];
      },
      transformResponse: (response: { data: AccountSupplier }) => response.data,
    }),
    deleteAccountSupplier: build.mutation<void, string>({
      query: (id) => {
        return {
          url: `/account/suppliers/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, id) => [
        {
          type: 'Account Supplier' as const,
          id,
        },
        { type: 'Account Supplier', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
});

export const {
  useGetAccountSupplierListQuery,
  useGetAccountSupplierDetailsQuery,
  useAddAccountSupplierMutation,
  useUpdateAccountSupplierMutation,
  useDeleteAccountSupplierMutation,
} = accountSupplierApi;
