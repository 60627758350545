// Libraries
import { Box, Typography } from '@mui/material';
import { TERM_OF_USE_VERSION } from '../../constants/constants';

// Components
import TermsOfServiceModal from '../TermsOfServiceModal/TermsOfServiceModal';

// scss
import './TermsOfService.scss';

const TermsOfService = () => {
  return (
    <Box className='terms-of-service'>
      <Typography className='terms-of-service-text' variant='body1'>
        Bluon® {TERM_OF_USE_VERSION}.
      </Typography>
      <TermsOfServiceModal />
    </Box>
  );
};

export default TermsOfService;
