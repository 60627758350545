// Libraries
import cx from 'classnames';
import {
  Button,
  Stack,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Box,
} from '@mui/material';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useState } from 'react';

// Components
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import LoadingButton from '../LoadingButton/LoadingButton';
import PhoneFieldInput from '../PhoneFieldInput/PhoneFieldInput';
import TextFieldInput from '../TextFieldInput/TextFieldInput';

// Files
import { US_STATES } from '../../constants/states';
import { AccountConfirmFormProps } from './interface';
import useUpdateCompanyForm from '../../hooks/useUpdateCompanyForm/useUpdateCompanyForm';

// Scss
import './AccountConfirmForm.scss';

const AccountConfirmForm = ({
  data,
  onSkip = () => {},
  onSuccess = () => {},
}: AccountConfirmFormProps) => {
  const [disabled, setDisabled] = useState(true);
  const [methods, handleSubmit, { isLoading }] = useUpdateCompanyForm({
    data,
    onSuccess,
  });

  const { control, formState, getFieldState, reset } = methods;
  const { error: serviceFieldsError } = getFieldState(
    'company_services',
    formState,
  );
  const { fields: serviceFields } = useFieldArray({
    control,
    name: 'company_services',
  });

  // Functions
  const handleDiscard = () => {
    setDisabled(true);
    reset();
  };

  return (
    <Box className='onboarding-step-form-container'>
      <FormProvider {...methods}>
        <form
          className={cx('account-confirm-form', { 'is-disabled': disabled })}
          onSubmit={handleSubmit}
        >
          <Box className='onboarding-step-form'>
            <Grid container>
              <Grid item xs={12}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='name'
                  label='Company Name'
                  className='account-field-input'
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='years_in_business'
                  label='Years in Business'
                  className='account-field-input'
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='number_of_techs'
                  label='Number of Techs'
                  className='account-field-input'
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <PhoneFieldInput
                  disabled={disabled}
                  fullWidth
                  name='phone'
                  label='Phone Number'
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='email'
                  label='Email Address'
                  className='account-field-input'
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='address'
                  label='Address 1'
                  className='account-field-input'
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='address_2'
                  label='Address 2'
                  className='account-field-input'
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='city'
                  label='City'
                  className='account-field-input'
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  disabled={disabled}
                  fullWidth
                  name='state'
                  label='State'
                  items={US_STATES}
                  className='account-field-input'
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldInput
                  disabled={disabled}
                  fullWidth
                  name='zip_code'
                  label='Zip'
                  className='account-field-input'
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  className='account-company-services'
                  name='services'
                  component='fieldset'
                  error={!!serviceFieldsError}
                >
                  <Typography variant='h6'>Company Services</Typography>

                  <FormGroup>
                    <Box className='services-container'>
                      {serviceFields.map((field, index) => (
                        <FormControlLabel
                          key={field.id}
                          control={
                            <Checkbox
                              disabled={disabled}
                              name={`company_services[${index}].default_checked`}
                            />
                          }
                          label={
                            <Typography variant='body1'>
                              {field.service_name}
                            </Typography>
                          }
                        />
                      ))}
                    </Box>
                  </FormGroup>
                  <FormHelperText>{serviceFieldsError?.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box className='onboarding-step-actions'>
            <Stack
              className='account-confirm-form__actions'
              direction='row'
              spacing={2}
            >
              {disabled ? (
                <>
                  <Button
                    size='large'
                    variant='outlined'
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    Edit
                  </Button>

                  <Button size='large' variant='contained' onClick={onSkip}>
                    All good, go to step 3!
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size='large'
                    variant='outlined'
                    onClick={handleDiscard}
                  >
                    CANCEL
                  </Button>

                  <LoadingButton
                    loading={isLoading}
                    size='large'
                    variant='contained'
                    type='submit'
                  >
                    Save changes
                  </LoadingButton>
                </>
              )}
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AccountConfirmForm;
