// Libraries
import { forwardRef, useCallback } from 'react';
import { Close } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { useSnackbar, SnackbarContent } from 'notistack';

// Files
import { ExtendedStyledAlertProps } from './interface';
import { DEFAULT_ACTION_LABEL } from '../../constants/toastConfig';

// Scss
import './StyledAlert.scss';

const StyledAlert = forwardRef<HTMLDivElement, ExtendedStyledAlertProps>(
  (
    {
      id,
      title,
      message,
      actionLabel = DEFAULT_ACTION_LABEL,
      severity = 'info',
      persist,
      onActionClick,
    },
    ref,
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const handleActionClick = useCallback(() => {
      if (!onActionClick) return;
      onActionClick(id);
    }, [id, onActionClick]);

    return (
      <SnackbarContent ref={ref}>
        <Alert
          className='styled-toast'
          elevation={1}
          severity={severity}
          onClose={handleDismiss}
          action={
            persist ? (
              <Button color='inherit' size='small' onClick={handleActionClick}>
                {actionLabel}
              </Button>
            ) : (
              <IconButton
                color='inherit'
                aria-label='Close Alert'
                size='small'
                onClick={handleDismiss}
              >
                <Close fontSize='small' />
              </IconButton>
            )
          }
        >
          <AlertTitle>{title}</AlertTitle>

          <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
        </Alert>
      </SnackbarContent>
    );
  },
);

export default StyledAlert;
