// Libraries
import { Box, IconButton } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useRef } from 'react';
import cx from 'classnames';

// Files
import useVideoPlayer from './hooks/useVideoPlayer';
import { VideoProps } from './interface';

// Scss
import './Video.scss';

const Video = ({ src }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { isPlaying, isTouched, methods } = useVideoPlayer(videoRef);
  const { onPlay } = methods;

  return (
    <Box
      className={cx('video', {
        'is-playing': isPlaying,
        'is-touched': isTouched,
      })}
    >
      <Box className='video__container'>
        <video controls={isTouched} ref={videoRef} src={src} {...methods}/>
      </Box>

      <IconButton aria-label='Play video' onClick={onPlay}>
        <PlayCircleOutlineIcon fontSize='inherit' />
      </IconButton>
    </Box>
  );
};

export default Video;
