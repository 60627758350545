export const REQUIRED_FIELD = 'This field is required.';

export const INVALID_EMAIL = 'Invalid email format';
export const INVALID_EMAIL_V2 =
  'Email format not recognized. Must be in username@domain.com format.';
export const REQUIRED_EMAIL = 'Please enter your email address.';

export const INVALID_PASSWORD =
  'Must be at least 8 characters and contain 1 number, 1 uppercase and 1 lowercase character.';
export const REQUIRED_PASSWORD = 'Please enter your password.';

export const REQUIRED_PHONE_NUMBER = 'Please enter a valid phone number.';
export const INVALID_PHONE_NUMBER = 'Please enter a valid phone number.';
export const INVALID_FILE_SIZE = 'Your file is too large. Max size of 3MB.';

export const INVALID_FILE_EXTENSION =
  'This file extension is not allowed. We only accept .SVG, .PNG, .JPG, .JPEG, or .GIF files.';
