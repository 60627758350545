// Libraries
import { CircularProgress, Box } from '@mui/material';

const StateHandlerLoading = () => (
  <Box className='state-handler state-handler--loading'>
    <CircularProgress />
  </Box>
);

export default StateHandlerLoading;
