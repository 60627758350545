import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogoState, LogoUrlState } from './interface';

import companyApi from '../company/companySlice';
import accountApi from '../account/accountSlice';

const initialState: LogoState = {
  url: '',
};

const logoSlice = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, { payload: { url } }: PayloadAction<LogoUrlState>) => ({
      ...state,
      url: url,
    }),
  },
  extraReducers(builder) {
    builder.addMatcher(
      companyApi.endpoints.updateCompany.matchFulfilled,
      (state, { payload }) => {
        if (state?.url !== payload?.data?.logo?.url) {
          return { ...state, url: payload.data?.logo?.url };
        }

        return { ...state };
      },
    );

    builder.addMatcher(
      accountApi.endpoints.getAccountInfo.matchFulfilled,
      (state, { payload }) => {
        if (
          payload?.data?.logo?.url &&
          state?.url !== payload?.data?.logo?.url
        ) {
          return { ...state, url: payload?.data?.logo?.url };
        }

        return { ...state };
      },
    );
  },
});

export const { setLogo } = logoSlice.actions;

export default logoSlice.reducer;
