// Components
import AccountConfirmForm from '../../../../common/components/AccountConfirmForm/AccountConfirmForm';
import StateHandlerLoading from '../../../../common/components/StateHandler/components/StateHandlerLoading/StateHandlerLoading';
import StateHandlerError from '../../../../common/components/StateHandler/components/StateHandlerError/StateHandlerError';

// Files
import { OnboardingStepComponentProps } from '../../interface';
import { useGetCompanyAndServicesQuery } from '../../../../features/company/companySlice';

// Scss
import './OnboardingStepTwo.scss';

const OnboardingStepTwo = ({ onNextPage }: OnboardingStepComponentProps) => {
  const { data, isLoading } = useGetCompanyAndServicesQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return (
    <AccountConfirmForm
      data={data}
      onSkip={onNextPage}
      onSuccess={onNextPage}
    />
  );
};

export default OnboardingStepTwo;
