// Files
import { AxiosError } from 'axios';
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import {
  Company,
  UpdateCompanyResponse,
  UpdateCompanyFormData,
  getCompanyAndServicesResponse,
  updateCompanyLogoResponse,
  updateCompanyLogoFormData,
  CompanyTechnician,
  VerifyCompanyFormData,
  VerifyCompanyResponse,
} from './interface';

const fakeTechniciansList = [
  {
    id: 1,
    confirmed: true,
    name: 'Fake Name 1',
  },
  {
    id: 2,
    confirmed: false,
    name: 'Fake Name 2',
  },
  {
    id: 3,
    confirmed: false,
    name: 'Fake Name 3',
  },
  {
    id: 4,
    confirmed: false,
    name: 'Fake Name 4',
  },
] as CompanyTechnician[];

// We use this to simulate a fetch query
// until the backend API supports that
function simulateFetchLoading<T>(data: T) {
  return new Promise<T>((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 2000);
  });
}

const companyApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyAndServices: build.query<getCompanyAndServicesResponse, void>({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const data = {} as getCompanyAndServicesResponse;

        const companyResult = await baseQuery({
          url: '/company',
          method: 'GET',
        });

        if (companyResult.error) {
          return { error: companyResult.error as AxiosError };
        }
        data.company =
          companyResult.data as getCompanyAndServicesResponse['company'];

        const servicesResult = await baseQuery({
          url: '/services',
          method: 'GET',
        });

        if (companyResult.error) {
          return { error: servicesResult.error as AxiosError };
        }
        data.services =
          servicesResult.data as getCompanyAndServicesResponse['services'];

        return { data };
      },
      providesTags: () => [{ type: 'Company', id: 'DETAILS' }],
    }),
    getCompany: build.query<Company, void>({
      query: () => ({
        url: '/company',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Company', id: 'DETAILS' }],
      transformResponse: (response: { data: Company }) => response.data,
    }),
    getCompanyTechnicians: build.query<CompanyTechnician[], void>({
      async queryFn() {
        const result = await simulateFetchLoading(fakeTechniciansList);

        return { data: result };
      },
      providesTags: () => [{ type: 'Company', id: 'DETAILS' }],
    }),
    updateCompany: build.mutation<UpdateCompanyResponse, UpdateCompanyFormData>(
      {
        query: (data) => ({
          url: '/company',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: {
            ...data,
            // This is needed, due to backend inability to process formData
            // if the query  method is not 'POST'
            _method: 'PATCH',
          },
        }),
        invalidatesTags: [{ type: 'Company', id: 'DETAILS' }],
      },
    ),
    updateCompanyLogo: build.mutation<
      updateCompanyLogoResponse,
      updateCompanyLogoFormData
    >({
      query: (data) => ({
        url: '/company/logo',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data,
      }),
      invalidatesTags: [{ type: 'Company', id: 'DETAILS' }],
    }),
    verifyCompany: build.mutation<VerifyCompanyResponse, VerifyCompanyFormData>(
      {
        query: (data) => ({
          url: '/company/verify',
          method: 'PATCH',
          data: data,
        }),
      },
    ),
  }),
});

export default companyApi;

export const {
  useGetCompanyTechniciansQuery,
  useGetCompanyAndServicesQuery,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyLogoMutation,
  useVerifyCompanyMutation,
} = companyApi;
