import * as Yup from 'yup';

import { phoneRegExp } from '../../../constants/regExp';
import {
  REQUIRED_FIELD,
  REQUIRED_EMAIL,
  INVALID_EMAIL,
} from '../../../constants/validationStrings';
import { logoValidationSchema } from '../../../validations/validations';

const service = Yup.object().shape({
  service_id: Yup.string(),
  service_name: Yup.string(),
  default_checked: Yup.boolean(),
});

Yup.addMethod(
  Yup.array,
  'notEveryServiceIsFalse',
  function (message: string = '') {
    return this.test('notEveryServiceIsFalse', message, (services) => {
      if (!services) {
        return false;
      }

      return !services.every((service) => service.default_checked === false);
    });
  },
);

export const companyFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Company name must be at least 3 characters long'),
  email: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .required(REQUIRED_EMAIL)
    .email(INVALID_EMAIL),
  phone: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .matches(phoneRegExp, 'Phone number is not valid'),
  address: Yup.string(),
  address_2: Yup.string().nullable(),
  city: Yup.string(),
  state: Yup.string(),
  zip_code: Yup.string(),
  years_in_business: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .max(3, 'Maximum number of Years in Business cannot be greater than 999'),
  number_of_techs: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .max(3, 'Maximum number of techs cannot be greater than 999'),
  company_services: Yup.array()
    .of(service)
    .notEveryServiceIsFalse('You must select at least 1 company service'),
  logo: logoValidationSchema,
});
