/**
 * External Dependencies
 */

import { Box, Typography } from '@mui/material';

/**
 * Internal Dependencies
 */
import SearchInput from '../../../common/components/SearchInput/SearchInput';
import TechniciansAddModal from './../../../common/components/TechniciansAddModal/TechniciansAddModal';

export interface TechniciansTopBarProps {
  onSearch: (query: string) => void;
}

const TechniciansTopBar = ({ onSearch = () => {} }: TechniciansTopBarProps) => {
  return (
    <Box className='flex h-[100px] w-full items-center justify-between'>
      <Box className='flex items-center gap-4'>
        <Box>
          <Typography variant='h4'>Your Techs on Bluon</Typography>
        </Box>

        <Box>
          <SearchInput placeholder='Search Techs' onSearch={onSearch} />
        </Box>
      </Box>

      <Box>
        <TechniciansAddModal />
      </Box>
    </Box>
  );
};

export default TechniciansTopBar;
