import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { show as showNiceModal } from '@ebay/nice-modal-react';

// Libraries
import { AppBar, Grid, Toolbar } from '@mui/material';
import { NavLink, To } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useGetCompanyQuery } from '../../../features/company/companySlice';
import { NavMenuItem } from '../NavMenuItem';
import { NavDropDown } from '../NavDropDown';
import bluonContractorLogo from '../../../assets/images/bluon-contractor-logo-dark.svg';

import './styles.scss';

export interface NavRoute {
  path: To;
  icon: JSX.Element;
  title: string;
  action?: () => void;
  target?: string;
  type?: 'external' | 'internal';
}

export const NavRoutes: NavRoute[] = [
  {
    path: '/technicians',
    title: 'Team',
    icon: <GroupsIcon />,
  },
  {
    path: '/stores',
    title: 'Stores',
    icon: <WarehouseIcon />,
  },
  {
    path: '',
    title: 'Orders',
    icon: <AirportShuttleIcon />,
    action: () => {
      showNiceModal('upcoming-orders-page');
    },
  },
];

export const DropdownRoutes: NavRoute[] = [
  {
    path: '/account',
    title: 'Account',
    icon: <PersonIcon />,
  },
  {
    path: '',
    title: 'Log Out',
    icon: <LogoutIcon />,
  },
];

const NavBar = () => {
  const [logoUrl, setLogoUrl] = useState('');

  const { data } = useGetCompanyQuery();

  useEffect(() => {
    const newLogo = data?.logo?.url || '';
    if (newLogo) setLogoUrl(newLogo);
  }, [data]);

  return (
    <AppBar position='sticky' className='bluon-nav' color='inherit'>
      <Toolbar disableGutters>
        <Grid item xs className='container-logo'>
          <NavLink
            to='/technicians'
          >
            <img src={bluonContractorLogo} alt='Bluon Live' />
          </NavLink>
        </Grid>

        <Grid item xs={6} className='container-routes'>
          {NavRoutes.map((route) => (
            <NavMenuItem
              key={route.title}
              path={route.path}
              text={route.title}
              icon={route.icon}
              onClick={route.action}
            />
          ))}
        </Grid>

        <NavDropDown logo={logoUrl} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
