import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { axiosInstance } from '../../axios/axios';
import { RootState } from '../../redux/store';
import { AxiosBaseQueryFn } from './interface';

// endpoint configs -> axiosInstance -> actual api call
// therefore all configs must be set before passing config into axiosInstance

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosBaseQueryFn, unknown, unknown> =>
  async (
    { url, method, data, params, headers, onUploadProgress }: AxiosBaseQueryFn,
    { getState }: BaseQueryApi,
  ) => {
    const config: AxiosBaseQueryFn = {
      url,
      method,
      data,
      params,
      headers,
      onUploadProgress,
    };
    const token = (getState() as RootState).persist.token;

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      } as RawAxiosRequestHeaders;
    }

    try {
      const result = await axiosInstance(config);

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
