import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import LoginLoader from '../LoginLoader/LoginLoader';
import { LoginLoaderContextProps, LoginLoaderProviderProps } from './interface';

const LoginLoaderContext = createContext({} as LoginLoaderContextProps);

export const useLoginLoader = (): LoginLoaderContextProps => {
  return useContext(LoginLoaderContext);
};

const LoginLoaderProvider = ({ children }: LoginLoaderProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const value = useMemo(() => ({ showLoader }), [showLoader]);

  return (
    <LoginLoaderContext.Provider value={value}>
      <LoginLoader isLoading={isLoading} />
      {children}
    </LoginLoaderContext.Provider>
  );
};

export default LoginLoaderProvider;
