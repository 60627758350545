// Libraries
import { Fragment, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// Components
import StoresTopBar from './Partials/StoresTopBar';
import StoresCards from './Partials/StoresCards';
import StateHandler from '../../common/components/StateHandler/StateHandler';
import StateHandlerEmpty from '../../common/components/StateHandlerEmpty/StateHandlerEmpty';

// Files
import useNavigateSearch from '../../common/hooks/useNavigateSearch/useNavigateSearch';

// Features
import { useGetAccountSupplierListQuery } from '../../features/accountSupplier/accountSupplierSlice';
import useInfiniteScrollQuery from '../../common/hooks/useInfiniteScrollQuery/useInfiniteScrollQuery';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const Stores = () => {
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<FieldValues>({
    filter: searchParams.get('filter') || '',
  });
  const hasFiltersApplied = !!filters?.filter;
  const queryParams = {
    ...filters,
  };

  const navigateSearch = useNavigateSearch({
    replace: true,
  });

  const {
    combinedData,
    hasNextPage,
    isLoading,
    isFetching,
    loadMore,
    cleanData,
  } = useInfiniteScrollQuery(useGetAccountSupplierListQuery, {
    ...queryParams,
  });

  const isEmtyPage = useMemo(
    () => !isFetching && !combinedData.length,
    [isFetching, combinedData?.length],
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 40px 0px',
  });

  const handleOnFiltersChange = (data: FieldValues) => {
    const newQueryParams = { ...data };
    setFilters({ ...data });
    cleanData();
    navigateSearch('/stores', newQueryParams);
  };

  return (
    <Box className='w-full p-4'>
      <StoresTopBar
        shouldHideFilters={isEmtyPage && !hasFiltersApplied}
        onChange={handleOnFiltersChange}
      />
      <Box className='stores-list rounded-md bg-white p-10'>
        <StateHandler
          isLoading={isLoading}
          isError={combinedData === null}
          isEmpty={isEmtyPage}
          emptyComponent={
            <StateHandlerEmpty
              title={
                hasFiltersApplied
                  ? 'No stores match your criteria'
                  : 'No Stores... Yet!'
              }
              text={
                hasFiltersApplied
                  ? ''
                  : 'Use the “Add Store” button to select your preferred distributors!'
              }
            />
          }
        >
          {combinedData && (
            <Fragment>
              <StoresCards data={combinedData} />

              {(isLoading || hasNextPage || isFetching) && (
                <Box
                  display='flex'
                  justifyContent='center'
                  padding='1rem'
                  ref={sentryRef}
                >
                  <CircularProgress size='1.5rem' />
                </Box>
              )}
            </Fragment>
          )}
        </StateHandler>
      </Box>
    </Box>
  );
};

export default Stores;
