// Libraries
import { Fragment, useState } from 'react';
import { Button } from '@mui/material';

// Components
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

// Files
import { StoreDeleteFormProps } from './interface';

import { useDeleteAccountSupplierMutation } from '../../../features/accountSupplier/accountSupplierSlice';
import useExtendedSnackbar from '../../hooks/useExtendedSnackbar/useExtendedSnackbar';
import {
  getStoreDeleteErrorToastProps,
  getStoreDeleteSuccessToastProps,
} from '../../utils/toast/store-toast';

const StoreDeleteForm = ({ id, name }: StoreDeleteFormProps) => {
  const [showToast] = useExtendedSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteAccountSupplier, { reset }] = useDeleteAccountSupplierMutation();

  const handleConfirm = () => {
    deleteAccountSupplier(id)
      .unwrap()
      .then(() => {
        const storeDeleteSuccessToastProps =
          getStoreDeleteSuccessToastProps(name);
        showToast(storeDeleteSuccessToastProps);
      })
      .catch(() => {
        const storeDeleteErrorToastProps = getStoreDeleteErrorToastProps(name);
        showToast(storeDeleteErrorToastProps);
      })
      .finally(() => {
        reset();
        handleClose();
      });
  };

  return (
    <Fragment>
      <Button color='error' onClick={handleOpen}>
        Delete Store
      </Button>

      <ConfirmationDialog
        open={open}
        title={`Are you sure you want to delete ${name} from Your Stores?`}
        content='By doing this you will remove it from Tech’s list of stores as well.'
        confirmButtonText='Yes, Delete'
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default StoreDeleteForm;
