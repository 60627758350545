export const US_STATES = [
  { value: 'US-AL', label: 'Alabama' },
  { value: 'US-AK', label: 'Alaska' },
  { value: 'US-AS', label: 'American Samoa' },
  { value: 'US-AZ', label: 'Arizona' },
  { value: 'US-AR', label: 'Arkansas' },
  { value: 'US-CA', label: 'California' },
  { value: 'US-CO', label: 'Colorado' },
  { value: 'US-CT', label: 'Connecticut' },
  { value: 'US-DE', label: 'Delaware' },
  { value: 'US-DC', label: 'District of Columbia' },
  { value: 'US-FL', label: 'Florida' },
  { value: 'US-GA', label: 'Georgia' },
  { value: 'US-GU', label: 'Guam' },
  { value: 'US-HI', label: 'Hawaii' },
  { value: 'US-ID', label: 'Idaho' },
  { value: 'US-IL', label: 'Illinois' },
  { value: 'US-IN', label: 'Indiana' },
  { value: 'US-IA', label: 'Iowa' },
  { value: 'US-KS', label: 'Kansas' },
  { value: 'US-KY', label: 'Kentucky' },
  { value: 'US-LA', label: 'Louisiana' },
  { value: 'US-ME', label: 'Maine' },
  { value: 'US-MD', label: 'Maryland' },
  { value: 'US-MA', label: 'Massachusetts' },
  { value: 'US-MI', label: 'Michigan' },
  { value: 'US-MN', label: 'Minnesota' },
  { value: 'US-UM', label: 'Minor Outlying Islands' },
  { value: 'US-MS', label: 'Mississippi' },
  { value: 'US-MO', label: 'Missouri' },
  { value: 'US-MT', label: 'Montana' },
  { value: 'US-NE', label: 'Nebraska' },
  { value: 'US-NV', label: 'Nevada' },
  { value: 'US-NH', label: 'New Hampshire' },
  { value: 'US-NJ', label: 'New Jersey' },
  { value: 'US-NM', label: 'New Mexico' },
  { value: 'US-NY', label: 'New York' },
  { value: 'US-NC', label: 'North Carolina' },
  { value: 'US-ND', label: 'North Dakota' },
  { value: 'US-MP', label: 'Northern Mariana Islands' },
  { value: 'US-OH', label: 'Ohio' },
  { value: 'US-OK', label: 'Oklahoma' },
  { value: 'US-OR', label: 'Oregon' },
  { value: 'US-PA', label: 'Pennsylvania' },
  { value: 'US-PR', label: 'Puerto Rico' },
  { value: 'US-RI', label: 'Rhode Island' },
  { value: 'US-SC', label: 'South Carolina' },
  { value: 'US-SD', label: 'South Dakota' },
  { value: 'US-TN', label: 'Tennessee' },
  { value: 'US-TX', label: 'Texas' },
  { value: 'US-VI', label: 'U.S. Virgin Islands' },
  { value: 'US-UT', label: 'Utah' },
  { value: 'US-VT', label: 'Vermont' },
  { value: 'US-VA', label: 'Virginia' },
  { value: 'US-WA', label: 'Washington' },
  { value: 'US-WV', label: 'West Virginia' },
  { value: 'US-WI', label: 'Wisconsin' },
  { value: 'US-WY', label: 'Wyoming' },
];

export const US_STATES_SHORT = [
  { value: 'US-AL', label: 'AL' },
  { value: 'US-AK', label: 'AK' },
  { value: 'US-AS', label: 'AS' },
  { value: 'US-AZ', label: 'AZ' },
  { value: 'US-AR', label: 'AR' },
  { value: 'US-CA', label: 'CA' },
  { value: 'US-CO', label: 'CO' },
  { value: 'US-CT', label: 'CT' },
  { value: 'US-DE', label: 'DE' },
  { value: 'US-DC', label: 'DC' },
  { value: 'US-FL', label: 'FL' },
  { value: 'US-GA', label: 'GA' },
  { value: 'US-GU', label: 'GU' },
  { value: 'US-HI', label: 'HI' },
  { value: 'US-ID', label: 'ID' },
  { value: 'US-IL', label: 'IL' },
  { value: 'US-IN', label: 'IN' },
  { value: 'US-IA', label: 'IA' },
  { value: 'US-KS', label: 'KS' },
  { value: 'US-KY', label: 'KY' },
  { value: 'US-LA', label: 'LA' },
  { value: 'US-ME', label: 'ME' },
  { value: 'US-MD', label: 'MD' },
  { value: 'US-MA', label: 'MA' },
  { value: 'US-MI', label: 'MI' },
  { value: 'US-MN', label: 'MN' },
  { value: 'US-UM', label: 'UM' },
  { value: 'US-MS', label: 'MS' },
  { value: 'US-MO', label: 'MO' },
  { value: 'US-MT', label: 'MT' },
  { value: 'US-NE', label: 'NE' },
  { value: 'US-NV', label: 'NV' },
  { value: 'US-NH', label: 'NH' },
  { value: 'US-NJ', label: 'NJ' },
  { value: 'US-NM', label: 'NM' },
  { value: 'US-NY', label: 'NY' },
  { value: 'US-NC', label: 'NC' },
  { value: 'US-ND', label: 'ND' },
  { value: 'US-MP', label: 'MP' },
  { value: 'US-OH', label: 'OH' },
  { value: 'US-OK', label: 'OK' },
  { value: 'US-OR', label: 'OR' },
  { value: 'US-PA', label: 'PA' },
  { value: 'US-PR', label: 'PR' },
  { value: 'US-RI', label: 'RI' },
  { value: 'US-SC', label: 'SC' },
  { value: 'US-SD', label: 'SD' },
  { value: 'US-TN', label: 'TN' },
  { value: 'US-TX', label: 'TX' },
  { value: 'US-VI', label: 'VI' },
  { value: 'US-UT', label: 'UT' },
  { value: 'US-VT', label: 'VT' },
  { value: 'US-VA', label: 'VA' },
  { value: 'US-WA', label: 'WA' },
  { value: 'US-WV', label: 'WV' },
  { value: 'US-WI', label: 'WI' },
  { value: 'US-WY', label: 'WY' },
];
