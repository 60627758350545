// Libraries
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

// Files
import { LoginFormHeaderProps } from './interface';

// scss
import './LoginFormHeader.scss';

const LoginFormHeader = ({
  header,
  subheader,
  note,
  className,
}: LoginFormHeaderProps) => {
  return (
    <Box className={`login-form-header-container ${className}`}>
      <Typography className='login-form-header' variant='h4'>
        {header}
      </Typography>
      <Typography className='login-form-subheader' variant='subtitle1'>
        {subheader}
      </Typography>
      <Typography className='login-form-note' variant='subtitle1'>
        {note}
      </Typography>
    </Box>
  );
};

export default LoginFormHeader;
