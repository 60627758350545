// Libraries
import Grid from '@mui/material/Grid';

// Components
import { TechniciansListProps } from './interface';
import { Technician } from '../../../features/technician/interface';
import TechnicianCard from './../../components/TechnicianCard/TechnicianCard';
import './styles.scss';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const TechniciansList = ({
  data,
  hasInvitations,
  hasNextPage,
  loadMore,
  isLoading,
}: TechniciansListProps) => (
  <Grid container className='technicians-list-container'>
    {data.map((item: Technician) => (
      <Grid item xs={12} sm={6} md={hasInvitations ? 4 : 3} key={item.id}>
        <TechnicianCard data={item} />
      </Grid>
    ))}

    {hasNextPage && !isLoading && (
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Button variant='outlined' color='primary' onClick={loadMore}>
          Load More
        </Button>
      </Grid>
    )}

    {isLoading && (
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <CircularProgress />
      </Grid>
    )}
  </Grid>
);

export default TechniciansList;
