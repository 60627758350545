export const enum ACCOUNT_SETUP_CODE {
  EMPTY = 'EMPTY',
  SUCCESS = 'SUCCESS',
  ALREADY_SETUP = 'ALREADY_SETUP',
  NOT_EXISTS = 'NOT_EXISTS',
}

export type AccountSetupState = AccountSetup;

export interface AccountSetup {
  email: string;
  code?: ACCOUNT_SETUP_CODE;
}

export interface AccountSetupPayload {
  email: string;
}

export interface AccountSetupResponse {
  code: ACCOUNT_SETUP_CODE;
}
