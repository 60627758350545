// Libraries
import { useState, useEffect } from 'react';

const useVideoPlayer = (
  videoElement: React.MutableRefObject<HTMLVideoElement | null>,
) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const togglePlay = (value: boolean = false) => {
    setIsTouched(true);
    setIsPlaying(value);
  };

  const methods = {
    onPlay: () => togglePlay(true),
    onPause: () => togglePlay(false),
  };

  // Side Effects
  useEffect(() => {
    if (!videoElement.current) return;

    isPlaying ? videoElement.current.play() : videoElement.current.pause();
  }, [isPlaying, videoElement]);

  return { isPlaying, isTouched, methods };
};

export default useVideoPlayer;
