// Libraries
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

// Scss
import './StyledDialog.scss';

// Files
import { StyledDialogProps } from './interface';

export default function StyledDialog({
  onClose,
  children,
  hideCloseButton,
  className,
  ...rest
}: StyledDialogProps) {
  return (
    <Dialog
      {...rest}
      onClose={onClose}
      className={`styled-dialog ${className}`}
    >
      {onClose && !hideCloseButton ? (
        <IconButton
          size='medium'
          aria-label='close'
          onClick={onClose}
          className='styled-dialog-close-button'
        >
          <CancelIcon fontSize='inherit' />
        </IconButton>
      ) : null}

      {children}
    </Dialog>
  );
}
