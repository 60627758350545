// Libraries
import { Box } from '@mui/material';

// Components
import OnboardingHighlightHeader from '../OnboardingHighlightHeader/OnboardingHighlightHeader';

// Files
import { OnboardingImageHighlightProps } from './interface';

// Scss
import './OnboardingImageHighlight.scss';

// * Think of a way to dynamically draw the connection line
const OnboardingImageHighlight = ({
  src,
  title,
  subTitle,
}: OnboardingImageHighlightProps) => {
  return (
    <Box className='onboarding-image-highlight'>
      <Box className='onboarding-title-subtitle'>
          <OnboardingHighlightHeader title={title} subTitle={subTitle} type='image'/>
      </Box>

      <Box className='onboarding-image-highlight__media'>
        <img src={src} alt='' />
      </Box>
    </Box>
  );
};

export default OnboardingImageHighlight;
