// Libraries
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

// Files
import { StoreFilterFormHeaderProps } from './interface';

const StoreFilterFormHeader = ({
  title,
  children,
  ...props
}: StoreFilterFormHeaderProps) => {
  return (
    <Box className='store-add-form__header' {...props}>
      <Typography variant='h5'>{title}</Typography>
      {children && <Typography>{children}</Typography>}
    </Box>
  );
};

export default StoreFilterFormHeader;
