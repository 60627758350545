// Libraries
import { Grid, Box } from '@mui/material';

// Components
import StoreCard from '../../../common/components/StoreCard/StoreCard';

// Files
import { AccountSupplier } from '../../../features/accountSupplier/interface';

const StoresCards = ({ data }: { data: AccountSupplier[] }) => {
  return (
    <Box className='stores__cards'>
      <Grid container spacing={2}>
        {data.map((store: AccountSupplier) => (
          <Grid item xs={3} key={store.id}>
            <StoreCard data={store} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StoresCards;
