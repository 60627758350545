// import { SerializedError } from '@reduxjs/toolkit';
// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { LoginError, RTKError } from '../interfaces/interface';

// export const getLoginErrors = (
//   error: FetchBaseQueryError | SerializedError,
// ) => {
//   const rtkError = error as RTKError;
//   const loginError = rtkError.data as LoginError;

//   return {
//     message: loginError.message,
//     errors: loginError.errors,
//   };
// };

export const getLoginErrors = (error: unknown) => {
  console.log(error);
  // maybe i should use axios error
  const rtkError = error as RTKError;
  const loginError = rtkError.data as LoginError;

  return {
    message: loginError.message,
    errors: loginError.errors,
  };
};
