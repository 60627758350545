// Libraries
import { Button, Stack, Typography, Box } from '@mui/material';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';

// Components
import LoadingButton from '../LoadingButton/LoadingButton';
import CompanyConfirmTechsFormChip from './components/CompanyConfirmTechsFormChip/CompanyConfirmTechsFormChip';

// Files
import {
  CompanyConfirmTechsFormProps,
  CompanyConfirmTechsFormData,
} from './interface';

// Scss
import './CompanyConfirmTechsForm.scss';
import { useVerifyCompanyMutation } from '../../../features/company/companySlice';
import { VerifyCompanyFormData } from '../../../features/company/interface';
import { useNavigate } from 'react-router';
import CompanyConfirmTechsFormEmpty from './components/CompanyConfirmTechsFormEmpty/CompanyConfirmTechsFormEmpty';
import { useEffect, useState } from 'react';
import { ConfirmTechsModal } from '../ConfirmTechsModal';

const CompanyConfirmTechsForm = ({ data }: CompanyConfirmTechsFormProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [payload, setPayload] = useState<CompanyConfirmTechsFormData>();
  const [verifyCompany, { isLoading }] = useVerifyCompanyMutation();
  const methods = useForm<CompanyConfirmTechsFormData>({
    defaultValues: {
      technicians: data,
    },
  });

  const { control, handleSubmit } = methods;
  const { fields: techniciansFields, update } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'technicians',
  });

  useEffect(() => {
    handleBulkUpdate(true);
  }, []);

  // Updated all entries based with the provided parameter
  const handleBulkUpdate = (newValue: boolean = false): void => {
    for (let index = 0; index < techniciansFields.length; index++) {
      update(index, {
        ...techniciansFields?.[index],
        confirmed: newValue,
      });
    }
  };

  const handleSubmitForm = (payload: CompanyConfirmTechsFormData) => {
    if (data.length > 1) {
      setIsOpen(true);
      setPayload(payload);
    } else {
      handleOnSubmit(payload);
    }
  };

  const onAcceptModal = () => {
    if (!payload) return;

    setIsOpen(false);
    handleOnSubmit(payload);
  };

  const handleOnSubmit = (data: CompanyConfirmTechsFormData) => {
    const technicianIds = data.technicians
      .filter(
        (technician) => technician.confirmed && technician.role !== 'admin',
      )
      .map((technician) => technician?.user?.id?.toString());

    const verifyCompanyFormData = {
      users: technicianIds,
    } as VerifyCompanyFormData;

    verifyCompany(verifyCompanyFormData)
      .unwrap()
      .then((res) => {
        // TODO this is temporary solution, and better solution needs to be added
        if (!res.data) {
          throw new Error();
        }
        setTimeout(() => {
          navigate('/technicians', {
            state: { showUpcomingFeatureModal: true },
          });
        }, 500);
      })
      .catch(() => {
        console.log('Something went wrong...');
      });
  };

  return (
    <Box className='onboarding-step-form-container'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <ConfirmTechsModal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onAccept={onAcceptModal}
          />
          {data.length > 1 ? (
            <>
              <Box className='onboarding-step-form'>
                <Box className='onboarding-techs-actions'>
                  <Stack
                    spacing={2}
                    direction='row'
                    className='company-confirm-techs-form__actions'
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => handleBulkUpdate()}
                    >
                      UNSELECT ALL
                    </Button>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => handleBulkUpdate(true)}
                    >
                      SELECT ALL
                    </Button>
                  </Stack>
                </Box>
                <Box className='onboarding-techs-list'>
                  <Box className='onboarding-techs-list-items'>
                    {techniciansFields.map((field, index) => {
                      if (field.role === 'admin') return <></>;

                      return (
                        <div key={field.fieldId}>
                          <CompanyConfirmTechsFormChip
                            name='technicians'
                            index={index}
                          />
                        </div>
                      );
                    })}
                  </Box>
                </Box>
                <Box className='onboarding-techs-info'>
                  <Typography
                    variant='inherit'
                    color='primary'
                    className='text-xs'
                  >
                    You’ll be able to add the rest of your techs on the next
                    page.
                  </Typography>
                </Box>
              </Box>

              <Box className='onboarding-step-actions'>
                <Stack
                  className='company-confirm-techs-form__footer'
                  spacing={2}
                >
                  <Box>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      size='medium'
                      loading={isLoading}
                      type='submit'
                    >
                      CONFIRM TECHS AND PROCEED
                    </LoadingButton>
                  </Box>
                </Stack>
              </Box>
            </>
          ) : (
            <CompanyConfirmTechsFormEmpty isLoading={isLoading} />
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default CompanyConfirmTechsForm;
