// Libraries
import { Box, Typography } from '@mui/material';

// Files
import { OnboardingHighlightHeaderProps } from './interface';

// Scss
import './OnboardingHighlightHeader.scss';

const OnboardingHighlightHeader = ({
  title,
  subTitle,
  type
}: OnboardingHighlightHeaderProps) => {
  if (!title && !subTitle) return null;

  return (
    <Box className={type === 'image' ? 'onboarding-highlight-image-header' : 'onboarding-highlight-video-header'}>
      {title && <Typography variant='h3'>{title}</Typography>}

      {subTitle && <Typography variant='h5'>{subTitle}</Typography>}
    </Box>
  );
};

export default OnboardingHighlightHeader;
