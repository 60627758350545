//  Libraries
import GoogleMapReact from 'google-map-react';
import Box from '@mui/material/Box';

// Components
import GoogleMapMarker from '../GoogleMapMarker/GoogleMapMarker';

// Files
import { GoogleMapProps } from './interface';

// Scss
import './GoogleMap.scss';

export default function GoogleMap({ lat, lng, zoom = 16 }: GoogleMapProps) {
  return (
    <Box className='google-map'>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
        defaultCenter={{
          lat,
          lng,
        }}
        defaultZoom={zoom}
      >
        <GoogleMapMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </Box>
  );
}
