import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import {
  CreatePasswordFormData,
  CreatePasswordResponse,
  ForgotPasswordFormData,
  ResetPasswordFormData,
  ResetPasswordResponse,
} from './interface';

export const passwordApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    forgotPassword: build.mutation<void, ForgotPasswordFormData>({
      query: ({ data }: ForgotPasswordFormData) => {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          data,
        };
      },
    }),
    resetPassword: build.mutation<ResetPasswordResponse, ResetPasswordFormData>(
      {
        query: ({ data }: ResetPasswordFormData) => {
          return {
            url: '/auth/reset-password',
            method: 'POST',
            data,
          };
        },
        transformResponse(response: ResetPasswordResponse, meta, arg) {
          const { email, password } = arg.data;
          const resetPasswordResponse = {
            ...response,
            email,
            password,
          };

          return resetPasswordResponse;
        },
      },
    ),
    createPassword: build.mutation<
      CreatePasswordResponse,
      CreatePasswordFormData
    >({
      query: ({ data }: CreatePasswordFormData) => {
        return {
          url: '/auth/initial-password',
          method: 'POST',
          data,
        };
      },
      transformResponse: (response: { data: CreatePasswordResponse }) =>
        response.data,
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCreatePasswordMutation,
} = passwordApi;
