// Libraries
import { SnackbarProvider } from 'notistack';

// Components
import StyledAlert from '../StyledAlert/StyledAlert';

// Files
import { StyledAlertProps } from '../StyledAlert/interface';
import { ExtendedSnackbarProviderProps } from './interface';
import {
  AUTO_HIDE_DURATION,
  MAX_SNACK,
} from '../../../config/notistack/notistack';

// Adds `reportComplete` variant and specifies the
// "extra" props it takes in options of `enqueueSnackbar`
declare module 'notistack' {
  interface VariantOverrides {
    styledAlert: StyledAlertProps;
  }
}

const ExtendedSnackbarProvider = ({
  children,
}: ExtendedSnackbarProviderProps) => (
  <SnackbarProvider
    maxSnack={MAX_SNACK}
    autoHideDuration={AUTO_HIDE_DURATION}
    Components={{
      styledAlert: StyledAlert,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default ExtendedSnackbarProvider;
