import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import './styles.scss';

export interface ConfirmTechsModalProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const ConfirmTechsModal = ({
  onClose,
  open,
  onAccept,
}: ConfirmTechsModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='dialog-container'
    >
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant='h6' color='primary' textAlign='center'>
        Sure all techs are yours?
      </Typography>
      <Typography variant='body1' color='initial'>
        Make sure you selected all of your guys!
      </Typography>
      <DialogActions className='dialog-actions'>
        <Button onClick={onClose} variant='outlined'>
          I’ll check again
        </Button>
        <Button type='submit' onClick={onAccept} variant='contained' autoFocus>
          I’m good
        </Button>
      </DialogActions>
    </Dialog>
  );
};
