import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// scss
import './AccountSetupSuccess.scss';
import { useDispatch } from 'react-redux';
import { setCheckAccountSetupResult } from '../../../features/account-setup/accountSetupSlice';
import { ACCOUNT_SETUP_CODE } from '../../../features/account-setup/interface';

const AccountSetupSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReturnToLogin = () => {
    dispatch(
      setCheckAccountSetupResult({
        email: '',
        code: ACCOUNT_SETUP_CODE.EMPTY,
      }),
    );
    navigate('/login');
  };

  return (
    <Box className='account-setup-success'>
      <Box className='account-setup-success__container'>
        <Box className='account-setup-success__container__title'>
          <Typography
            variant='h4'
            className='account-setup-success__container__title__main'
          >
            Success!
          </Typography>
          <Typography
            variant='subtitle1'
            className='account-setup-success__container__title__sub'
          >
            We sent you an email with your login steps. It may take a few
            minutes to arrive.
          </Typography>
        </Box>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          type='button'
          onClick={handleReturnToLogin}
        >
          RETURN TO LOGIN
        </Button>
      </Box>
    </Box>
  );
};

export default AccountSetupSuccess;
