// Libraries
import { Typography } from '@mui/material';
import { Fragment } from 'react';

// Components
import StateHandlerLoading from './components/StateHandlerLoading/StateHandlerLoading';
import StateHandlerError from './components/StateHandlerError/StateHandlerError';

// Files
import { StateHandlerProps } from './interface';

// Scss
import './StateHandler.scss';

const StateHandler = ({
  isLoading,
  isError,
  isEmpty,
  emptyComponent,
  children,
}: StateHandlerProps) => {
  if (isLoading) return <StateHandlerLoading />;

  if (isEmpty) {
    return emptyComponent ? (
      <Fragment>{emptyComponent}</Fragment>
    ) : (
      <Typography variant='h6'>No results found</Typography>
    );
  }

  if (isError) return <StateHandlerError />;

  return <Fragment>{children}</Fragment>;
};

export { StateHandlerLoading, StateHandlerError };
export default StateHandler;
