// TODO Handle Technician Invitation Approve/Deny
/* eslint-disable @typescript-eslint/no-unused-vars */
// Libraries
import {
  Card,
  CardActions,
  Typography,
  CardContent,
  Button,
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

// Files
import { TechnicianInvitationCardProps } from './interface';
import { useUpdateInvitationMutation } from '../../../features/technician-invitation/technicianInvitationSlice';
import useExtendedSnackbar from '../../hooks/useExtendedSnackbar/useExtendedSnackbar';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';

// Scss
import './TechnicianInvitationCard.scss';

const TechnicianInvitationCard = ({ data }: TechnicianInvitationCardProps) => {
  const [updateInvitation, updateInvitationResponse] =
    useUpdateInvitationMutation();
  const [showToast] = useExtendedSnackbar();

  const handleDecline = () => {
    // TODO handleDecline
  };

  const handleApprove = () => {
    // TODO  handleApprove
  };

  return (
    <Card elevation={1} className='technician-invitation-card'>
      <CardContent>
        {data.phone?.user?.name && (
          <Typography variant='body1'>{data.phone?.user?.name}</Typography>
        )}

        <Typography variant='body2'>
          {formatPhoneNumber(data.phone?.phone)}
        </Typography>

        {data.initiated_by === 'contractor' && (
          <Typography variant='body2'>
            Request sent approval pending.
          </Typography>
        )}
      </CardContent>

      {data.initiated_by === 'technician' && (
        <CardActions disableSpacing>
          <Button
            startIcon={<CheckCircle />}
            onClick={handleApprove}
            color='success'
          >
            Approve
          </Button>

          <Button color='error' startIcon={<Cancel />} onClick={handleDecline}>
            Deny
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default TechnicianInvitationCard;
