// Libraries
import { Grid } from '@mui/material';

// Files
import { StepperProps } from './interface';

// Scss
import './Stepper.scss';

const Stepper = ({ currentStep, totalSteps }: StepperProps) => {
  return (
    <Grid className='stepper' spacing={2} container>
      {[...Array(totalSteps).keys()].map((key) => (
        <Grid key={key} item xs={3}>
          <span className={key <= currentStep ? 'is-active' : ''} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Stepper;
