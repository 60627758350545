// Libraries
import React, { useEffect, useState } from 'react';

// Files
import capsLockIcon from '../../../assets/icons/caps-lock.svg';
import { KeyboardEvents } from '../../utils/interfaces/interface';

const CapsLock = () => {
  const [isCapsLock, setIsCapsLock] = useState(false);

  useEffect(() => {
    const keyboardEvents: KeyboardEvents[] = ['keydown', 'keyup'];
    keyboardEvents.forEach((eventName) => {
      document.addEventListener(eventName, (e) => {
        if (e?.getModifierState) {
          const capsLock = e.getModifierState('CapsLock');
          setIsCapsLock(capsLock);
        }
      });
    });
  });

  return (
    <img
      src={capsLockIcon}
      alt='caps-lock-indicator'
      style={{ display: isCapsLock ? '' : 'none' }}
    />
  );
};

export default CapsLock;
