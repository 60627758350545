import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery/axiosBaseQuery';

export const axiosApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    'Company',
    'Supplier',
    'Account Supplier',
    'Account',
    'Technician',
    'Technician Invitation',
    'Order',
    'Notification',
  ],
});
