import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import LoginFormHeader from '../../common/components/LoginFormHeader/LoginFormHeader';
import TextFieldInput from '../../common/components/TextFieldInput/TextFieldInput';
import VisibilityAdornment from '../../common/components/VisibilityAdornment/VisibilityAdornment';
import { INVALID_PASSWORD } from '../../common/constants/validationStrings';
import { CreatePasswordFormData } from '../../features/password/interface';

import { CreatePasswordValidationSchema } from './validations/CreatePasswordValidationSchema';

import './CreatePassword.scss';
import { CheckValidTokenData } from '../../features/token/interface';
import { useCheckValidToken } from '../../common/hooks/useCheckValidToken/useCheckValidToken';
import { useCreatePassword } from '../../common/hooks/useCreatePassword/useCreatePassword';

const CreatePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [createPassword] = useCreatePassword();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const { email } = queryParams;
  const { token } = useParams();

  const checkValidTokenFormData: CheckValidTokenData = {
    email,
    token,
  };

  const checkValidToken = useCheckValidToken(
    checkValidTokenFormData,
    queryParams,
  );

  const initialValues = {
    password: '',
    password_confirmation: '',
    agree_to_terms: true,
  };

  const methods = useForm({
    resolver: yupResolver(CreatePasswordValidationSchema),
    defaultValues: initialValues,
  });

  const { handleSubmit, formState } = methods;

  const handleOnSubmit = ({
    password,
    password_confirmation,
    agree_to_terms,
  }: FieldValues) => {
    checkValidToken();

    const createPasswordFormData: CreatePasswordFormData = {
      data: {
        token,
        email,
        password,
        password_confirmation,
        agree_to_terms,
      },
    };

    createPassword(createPasswordFormData);
  };

  return (
    <FormProvider {...methods}>
      <form
        className='initial-password'
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Grid item xs={8}>
          <LoginFormHeader
            className='initial-password-header'
            header='Set Password'
            subheader='This will set your password for the Bluon App and Bluon for Contractors. You may use your existing Bluon password.'
          />

          <TextFieldInput
            fullWidth
            className='initial-password-input-field'
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            adornment={
              <VisibilityAdornment
                showText={showPassword}
                setShowText={setShowPassword}
              />
            }
          />

          {!formState.errors.password?.type && (
            <Box className='password-text'>{INVALID_PASSWORD}</Box>
          )}

          <TextFieldInput
            fullWidth
            className='initial-password-new-password'
            name='password_confirmation'
            label='Re-Enter Password'
            type={showNewPassword ? 'text' : 'password'}
            adornment={
              <VisibilityAdornment
                showText={showNewPassword}
                setShowText={setShowNewPassword}
              />
            }
          />

          <Button fullWidth type='submit' variant='contained'>
            Set Password
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CreatePassword;
