// Libraries
import cx from 'classnames';
import { Box, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

// Files
import { TextFieldInputProps } from './interface';

// Scss
import './TextFieldInput.scss';

const TextFieldInput = ({
  name,
  helperText,
  adornment,
  className,
  ...rest
}: TextFieldInputProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: '',
  });

  // Prevent null inside input element
  const transformInput = (val: string) => val || '';

  return (
    <Box className={cx('text-field-input', className)}>
      <TextField
        autoComplete='off'
        value={transformInput(value)}
        onBlur={onBlur}
        onChange={onChange}
        error={!!error}
        helperText={error?.message || helperText}
        InputProps={{
          endAdornment: adornment,
        }}
        {...rest}
      />
    </Box>
  );
};

export default TextFieldInput;
