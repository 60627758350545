// Libraries
import { useCallback, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

// Components
import ButtonLink from '../ButtonLink/ButtonLink';
import ReactMarkdown from 'react-markdown';

// scss
import './TermsOfServiceModal.scss';
import { termsOfServiceText } from '../../constants/termsOfServiceText';
import { TermsOfServiceModalProps } from './interface';

const TermsOfServiceModal = ({
  setIsServiceRead,
  handleTOSOpen = () => {},
  handleTOSClose = () => {},
}: TermsOfServiceModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    handleTOSOpen();
    setOpen(true);
  };
  const handleClose = () => {
    handleTOSClose();
    setOpen(false);
  };

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && setIsServiceRead) setIsServiceRead(true);
  };
  const scrollDownRef = useCallback(
    (ref: HTMLDivElement) => {
      if (ref === null) return;
      const scrollHitsBottomObs = new IntersectionObserver(
        intersectionCallback,
      );
      scrollHitsBottomObs.observe(ref);
    },
    [open],
  );

  return (
    <Box>
      <ButtonLink
        className='terms-of-service-modal-button'
        onClick={handleOpen}
      >
        <Typography variant='body1'>
          Terms of Use &middot; Privacy Policy
        </Typography>
      </ButtonLink>

      <Dialog open={open} onClose={handleClose} scroll='paper'>
        <DialogTitle className='terms-title'>
          Terms of Service and Privacy Policies. Bluon, Inc.
        </DialogTitle>
        <DialogContent>
          <div className='terms-of-service-container'>
            <ReactMarkdown>{termsOfServiceText}</ReactMarkdown>
            <div
              ref={scrollDownRef}
              className='intersection-observer-dummy'
            ></div>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TermsOfServiceModal;
