import { Badge, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { NavMenuItemProps } from './interface';
import './styles.scss';

export const NavMenuItem = ({
  icon,
  text,
  path,
  badgeValue = 0,
  onClick,
}: NavMenuItemProps) => {
  if (path !== '')
    return (
      <NavLink
        onClick={onClick}
        key={String(path)}
        to={path}
        className={({ isActive }) =>
          isActive ? 'bluon-route-active' : 'inactive'
        }
      >
        <Box className='bluon-menu-item'>
          <Badge badgeContent={badgeValue} className='bluon-badge'>
            {icon}
          </Badge>
          <Typography variant='button' align='center'>
            {text}
          </Typography>
        </Box>
      </NavLink>
    );
  else
    return (
      <Box className='bluon-menu-item'>
        <div onClick={onClick} className='cursor-pointer flex flex-col'>
          <Badge badgeContent={badgeValue} className='bluon-badge'>
            {icon}
          </Badge>
          <Typography variant='button' align='center'>
            {text}
          </Typography>
        </div>
      </Box>
    );
};
