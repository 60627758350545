// Libraries
import { Box, Typography } from '@mui/material';

// Files
import { StateHandlerErrorProps } from './interface';

const StateHandlerError = ({
  title = 'Something went wrong...',
}: StateHandlerErrorProps) => (
  <Box className='state-handler state-handler--error'>
    <Typography variant='h6' color={'error'}>
      {title}
    </Typography>
  </Box>
);

export default StateHandlerError;
