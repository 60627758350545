import { capitalizeFirstLetter } from './Capitalize';

export const getShipmentAddress = (address: string[]): string => {
  if (address && address.length) {
    const filteredAddress = address
      .filter((x) => x)
      .map((x) => capitalizeFirstLetter(x))
      .join(', ');

    return filteredAddress;
  }

  return '';
};
