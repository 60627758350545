// Libraries
import { Fragment, useState } from 'react';
import {
  Box,
  Card,
  Stack,
  CardHeader,
  CardMedia,
  CardContent,
  IconButton,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Components
import StoreUpdateForm from '../StoreUpdateForm/StoreUpdateForm';
import StyledDrawer from '../StyledDrawer/StyledDrawer';
import StoreDetails from '../StoreDetails/StoreDetails';

// Files
import { StoreCardProps } from './interface';
import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';
import StyledDialog from '../StyledDialog/StyledDialog';

import ImageNotAvailable from '../../../assets/images/store-image-not-available.png';

// Scss
import './StoreCard.scss';
import { getShipmentAddress } from '../../../utils/strings/GetAddress';

export default function StoreCard({ data }: StoreCardProps) {
  const {
    id,
    name,
    image,
    phone,
    address,
    city,
    state,
    zip_code,
    published,
    company_supplier,
    company_supplier: { sales_rep_email, sales_rep_phone, sales_rep_name },
  } = data;
  const [dialogCloseFunction, setDialogCloseFunction] = useState(
    () => () => {},
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const showSupplierTooltip = !!(
    sales_rep_email ||
    sales_rep_phone ||
    sales_rep_name
  );

  const handleOnEdit = () => {
    setIsOpen(false);
    setIsEdit(true);
  };

  return (
    <Fragment>
      <Card
        className='store-card cursor-pointer'
        onClick={() => setIsOpen(true)}
      >
        <Box className='store-card__media'>
          <CardMedia
            className='store-card__image'
            component='img'
            height='300'
            image={image?.url || ImageNotAvailable}
            alt='Card Placeholder'
          />

          {published && (
            <Chip
              className='store-card__chip'
              label='On Bluon'
              color='primary'
              size='small'
              icon={<CheckCircleIcon />}
            />
          )}
        </Box>

        <CardHeader
          action={
            showSupplierTooltip ? (
              <Tooltip
                title={
                  <Typography variant='caption'>
                    {sales_rep_name && <p>{sales_rep_name}</p>}
                    {sales_rep_phone && (
                      <p>Phone: {formatPhoneNumber(sales_rep_phone)}</p>
                    )}
                    {sales_rep_email && <p>Email: {sales_rep_email}</p>}
                  </Typography>
                }
                arrow={true}
                placement='top'
              >
                <IconButton aria-label='Person Information' color='primary'>
                  <PersonIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : null
          }
          title={name}
        />

        {(address || phone) && (
          <CardContent>
            <Stack spacing={1}>
              {address && (
                <Typography
                  className='store-card__address'
                  component='address'
                  variant='body2'
                >
                  {getShipmentAddress([
                    address,
                    city ?? '',
                    state?.name ?? '',
                    zip_code ?? '',
                  ])}
                </Typography>
              )}

              {phone && (
                <Typography className='store-card__phone' variant='body2'>
                  {'Phone: '}
                  {formatPhoneNumber(phone)}
                </Typography>
              )}
            </Stack>
          </CardContent>
        )}
      </Card>

      <StyledDialog open={isEdit} onClose={dialogCloseFunction}>
        <StoreUpdateForm
          id={data.id}
          name={data.name}
          title={`Edit ${data.name}`}
          text='All fields are optional'
          companySupplier={company_supplier}
          onClose={setIsEdit}
          setDialogCloseFunction={setDialogCloseFunction}
        />
      </StyledDialog>

      <StyledDrawer
        anchor='right'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeIconClassName=' technician-card-detail-close-button'
      >
        <StoreDetails id={id} onEditClick={handleOnEdit} />
      </StyledDrawer>
    </Fragment>
  );
}
