import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { AccountSetupPayload, AccountSetupResponse } from './interface';

export const accountSetupApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    accountSetup: build.mutation<AccountSetupResponse, AccountSetupPayload>({
      query: (data: AccountSetupPayload) => {
        return {
          url: '/auth/resend-initial-password',
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const { useAccountSetupMutation } = accountSetupApi;
