// Libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/redux/hooks';

// Components
import ButtonLink from '../ButtonLink/ButtonLink';
import LoadingButton from '../LoadingButton/LoadingButton';
import LoginFormHeader from '../LoginFormHeader/LoginFormHeader';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import VisibilityAdornment from '../VisibilityAdornment/VisibilityAdornment';

// Features
import { useLoginMutation } from '../../../features/login/loginSlice';
import { LoginFormData } from '../../../features/login/interface';
import { setLoginAttempt } from '../../../features/auth/authSlice';
import {
  setCredentials,
  setUserData,
} from '../../../features/persist/persistSlice';
import { useLazyGetCompanyQuery } from '../../../features/company/companySlice';

// Files
import { LoginValidationSchema } from './validations/LoginValidationSchema';
import { INVALID_PASSWORD } from '../../constants/validationStrings';
import { AuthLoginAttemptState } from '../../../features/auth/interface';
import {
  AuthPersistCredentialState,
  setUserDataPayload,
} from '../../../features/persist/interface';
import { getLoginErrors } from '../../utils/rtk/rtk';

// scss
import './LoginForm.scss';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loginAttempt = useAppSelector((state) => state.auth.loginAttempt);
  const [getCompanyInfo, getCompanyInfoResponse] = useLazyGetCompanyQuery();
  const [login, loginResponse] = useLoginMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(LoginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  });

  const { handleSubmit, setError } = methods;
  const isLoading = useMemo(
    () => loginResponse.isLoading || getCompanyInfoResponse.isLoading,
    [loginResponse.isLoading, getCompanyInfoResponse.isLoading],
  );

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleOnSubmit = async ({ email, password }: FieldValues) => {
    const loginFormData: LoginFormData = {
      data: {
        email,
        password,
      },
    };

    login(loginFormData)
      .unwrap()
      .then((res) => {
        const payload: AuthPersistCredentialState = {
          userId: res.id,
          token: res.token,
        };
        dispatch(setCredentials(payload));

        return getCompanyInfo().unwrap();
      })
      .then((res) => {
        const payload: setUserDataPayload = {
          verifiedAt: res.verified_at,
        };

        dispatch(setUserData(payload));
      })
      .catch((error) => {
        const loginErrors = getLoginErrors(error);
        const payload: AuthLoginAttemptState = {
          loginAttempt: loginErrors.errors.attempts_left,
        };

        dispatch(setLoginAttempt(payload));

        const errorOptions = {
          type: 'custom',
          message: loginErrors.errors.password,
        };

        setError('email', errorOptions);
        setError('password', errorOptions);
      });
  };

  const handleFinishAccountSetup = () => {
    navigate('/account-setup');
  };

  return (
    <FormProvider {...methods}>
      <form className='login-form' onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid item xs={8}>
          <Box>
            <LoginFormHeader
              header='Log in to Bluon For Contractors'
              subheader='Please enter your username and password'
            />
            <Typography
              className='login-form-header-warning'
              style={{ display: loginAttempt > 2 ? 'none' : '' }}
            >
              For security reasons, after{' '}
              <span style={{ fontWeight: 700 }}>{`${loginAttempt} more`}</span>{' '}
              login attempts you will have to wait 10 minutes before trying
              again.
            </Typography>
            <TextFieldInput
              fullWidth
              className='login-form-email-field'
              variant='outlined'
              label='Email Address'
              name='email'
            />
            <TextFieldInput
              fullWidth
              variant='outlined'
              label='Password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              adornment={
                <VisibilityAdornment
                  showText={showPassword}
                  setShowText={setShowPassword}
                />
              }
            />
          </Box>

          <Box className='password-text'>{INVALID_PASSWORD}</Box>

          <Box>
            <LoadingButton
              fullWidth
              className='login-form-login-button'
              type='submit'
              variant='contained'
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
          </Box>

          <Divider className='login-form-divider'>
            <Typography variant='subtitle2' className='login-form-divider-text'>
              OR
            </Typography>
          </Divider>

          <Box className='login-form-first-time-access'>
            <Typography
              variant='body1'
              className='login-form-first-time-access-text'
            >
              Already onboarded, but need to set a password?
            </Typography>
            <Button
              fullWidth
              size='large'
              variant='outlined'
              onClick={handleFinishAccountSetup}
            >
              SET PASSWORD
            </Button>
          </Box>

          <Box className='login-form-forgot-password'>
            <ButtonLink onClick={handleForgotPassword}>
              <Typography variant='body1'>
                <span className='text-black'>Forgot Password?</span> Click Here
              </Typography>
            </ButtonLink>
          </Box>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
