// Action button
export const DEFAULT_ACTION_LABEL = 'GOT IT';

// error title
export const ERROR_TITLE = 'Oops, something went wrong.';

// password messages
export const PASSWORD_SUCCESS_TITLE = 'Password created, welcome!';
export const PASSWORD_SUCCESS_MESSAGE = 'You did it!';
export const PASSWORD_ERROR_MESSAGE = 'Please try again!';

// broken url messages
export const BROKEN_URL_MESSAGE =
  'It seems like you clicked on a broken url. Try to click on the link in your email again!';

// expired link messages
export const EXPIRED_LINK_MESSAGE =
  'Oh no! It seems like your link has expired. Click on the resend link button to get a new one!';

// store update messages
export const STORE_UPDATE_SUCCESS_TITLE = 'Changes saved';
export const STORE_UPDATE_SUCCESS_MESSAGE = 'information has been saved.';

export const STORE_UPDATE_ERROR_TITLE =
  'Unable to save store changes at this time';
export const STORE_UPDATE_ERROR_MESSAGE =
  'has not been edited. Please try again.';

// store add messages
export const STORE_ADD_SUCCESS_TITLE = 'Store Added';
export const STORE_ADD_SUCCESS_MESSAGE = 'has been added to your stores.';

export const STORE_ADD_ERROR_TITLE = 'Unable to add store at this time';
export const STORE_ADD_ERROR_MESSAGE = 'has not been added. Please try again.';

// store delete messages
export const STORE_REMOVE_SUCCESS_TITLE = 'Store Deleted';
export const STORE_REMOVE_SUCCESS_MESSAGE =
  'has been deleted from your stores.';

export const STORE_REMOVE_ERROR_TITLE = 'Unable to delete store at this time';
export const STORE_REMOVE_ERROR_MESSAGE =
  'has not been deleted. Please try again.';

// Account save messages
export const ACCOUNT_UPDATE_SUCCESS_TITLE = 'Changes Saved';
export const ACCOUNT_UPDATE_SUCCESS_MESSAGE = 'Your changes have been saved.';
export const ACCOUNT_UPDATE_ERROR_TITLE =
  'Unable to update account at this time';
export const ACCOUNT_UPDATE_ERROR_MESSAGE =
  'Account has not been edited. Please try again.';

// Technician Invitation
export const TECHNICIAN_CREATE_SUCCESS_TITLE = 'Success! ';
export const TECHNICIAN_CREATE_SUCCESS_MESSAGE =
  'We do not have a user with this number in our system so a text message has been sent to the phone number to join';
export const TECHNICIAN_CREATE_SUCCESS_WITH_USER_MESSAGE = 'Request sent to ';
export const TECHNICIAN_CREATE_ERROR_TITLE = 'Error';
export const TECHNICIAN_CREATE_ERROR_MESSAGE = 'Failed to send request';

//  Technician Details
export const TECHNICIAN_SKILL_LEVEL_PATCH_ERROR_TITLE = 'Error';
export const TECHNICIAN_SKILL_LEVEL_PATCH_ERROR_MESSAGE =
  'Failed to update skill level';
export const TECHNICIAN_SKILL_LEVEL_PATCH_SUCCESS_TITLE = 'Success! ';
export const TECHNICIAN_SKILL_LEVEL_PATCH_SUCCESS_MESSAGE =
  'Skill level has been updated.';
