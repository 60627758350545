// Components
import StateHandlerLoading from '../../../../common/components/StateHandler/components/StateHandlerLoading/StateHandlerLoading';
import StateHandlerError from '../../../../common/components/StateHandler/components/StateHandlerError/StateHandlerError';

// Components
import CompanyUpdateLogoForm from '../../../../common/components/CompanyUpdateLogoForm/CompanyUpdateLogoForm';

// Files
import { useGetAccountInfoQuery } from '../../../../features/account/accountSlice';
import { OnboardingStepComponentProps } from '../../interface';

const OnboardingStepThree = ({ onNextPage }: OnboardingStepComponentProps) => {
  const { data, isLoading } = useGetAccountInfoQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return <CompanyUpdateLogoForm onSuccess={onNextPage} />;
};

export default OnboardingStepThree;
