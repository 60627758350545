import { useEffect } from 'react';
import history from '../../../config/history/history';

export interface useBlockDirtyNavigationProps {
  isDirty: boolean;
  openDialog: (value: React.SetStateAction<boolean>) => void;
  setUrl: (value: React.SetStateAction<string>) => void;
  extraButtons: boolean[];
}

export const useBlockDirtyNavigation = ({
  isDirty,
  openDialog,
  setUrl,
  extraButtons,
}: useBlockDirtyNavigationProps) => {
  useEffect(() => {
    const unblock = history.block(({ retry, location: { pathname } }) => {
      if (!isDirty) {
        unblock();
        retry();

        return;
      }

      unblock();
      setUrl(pathname);
      openDialog(true);
    });

    return () => unblock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, ...extraButtons]);
};
