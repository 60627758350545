// TODO Show Orders page after MVP
/* eslint-disable @typescript-eslint/no-unused-vars */

// Libraries
import { useMediaQuery } from '@mui/material';
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import history from '../config/history/history';

// Components
import Login from '../pages/Login/Login';
import PrivateRoutes from '../routes/PrivateRoutes/PrivateRoutes';
import LoginRoutes from '../routes/LoginRoutes/LoginRoutes';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Technicians from '../pages/Technicians/Technicians';
import VerifyAccount from '../pages/VerifyAccount/VerifyAccount';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import LinkExpired from '../pages/LinkExpired/LinkExpired';
import LinkSent from '../pages/LinkSent/LinkSent';
import Account from '../pages/Account/Account';
import Stores from '../pages/Stores/Stores';
import Orders from '../pages/Orders/Orders';
import OrderDetails from '../pages/OrderDetails/OrderDetails';
import CreatePassword from '../pages/CreatePassword/CreatePassword';
import Onboarding from '../pages/Onboarding/Onboarding';
import ActiveOrders from '../pages/ActiveOrders/ActiveOrders';
import TechnicianDetails from '../pages/TechnicianDetails/TechnicianDetails';
import MobileNotification from '../pages/MobileNotification/MobileNotification';
import AccountSetup from '../pages/AccountSetup/AccountSetup';

// scss
import './App.scss';

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Show mobile notification on mobile devices
  if (isMobile) {
    return <MobileNotification />;
  }

  return (
    <div className='app'>
      {/* @ts-expect-error There's no functional issue here - this is just a TS error from using an old history@5 implementation with an unstable API in 6.4 which uses a different internal history. */}
      <Router history={history}>
        <Routes>
          <Route element={<LoginRoutes />}>
            <Route path={'/'} element={<Login />} />
            <Route path={'/login'} element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify-account' element={<VerifyAccount />} />
            <Route
              path='/create-password/:token'
              element={<CreatePassword />}
            />
            <Route path='/password-reset/:token' element={<ResetPassword />} />
            <Route path='/link-expired' element={<LinkExpired />} />
            <Route path='/link-sent' element={<LinkSent />} />
            <Route path='/account-setup' element={<AccountSetup />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path='/technicians' element={<Technicians />} />
            {/* <Route
              path='/technicians/:id/details'
              element={<TechnicianDetails />}
            />*/}
            <Route path='/account' element={<Account />} />
            <Route path='/stores' element={<Stores />} />
            {/* <Route path='/orders' element={<Orders />} />
            <Route path='/active-orders' element={<ActiveOrders />} />
            <Route path='/orders/:orderId/details' element={<OrderDetails />} /> */}
          </Route>

          <Route element={<PrivateRoutes keepHeader={false} />}>
            <Route path='/onboarding' element={<Onboarding />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
