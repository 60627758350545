// Libraries
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// Files
import { StoreSearchCardProps } from './interface';

import ImageNotAvailable from '../../../assets/images/store-image-not-available.png';
import LogoNotAvailable from '../../../assets/images/store-logo-not-available.png';

// Scss
import './StoreSearchCard.scss';
import { capitalizeFirstLetter } from '../../../utils/strings/Capitalize';

export default function StoreSearchCard({
  data,
  selected = false,
  onClick = () => {},
}: StoreSearchCardProps) {
  const {
    name,
    address,
    published,
    image,
    logo,
    distance,
    state,
    zip_code,
    city,
  } = data;

  const getShipmentAddress = (): string => {
    const addressItems = [
      state?.name,
      capitalizeFirstLetter(city?.toLowerCase() ?? ''),
      zip_code,
    ];

    const filteredAddress = addressItems.filter((x) => x !== null).join(', ');

    return filteredAddress;
  };

  return (
    <Card
      className={`store-search-card${selected ? ' is-selected' : ''}`}
      elevation={0}
      onClick={() => onClick(data)}
    >
      <Box className='store-search-card__media'>
        <CardMedia
          className='store-search-card__image'
          component='img'
          image={image ? image.url : ImageNotAvailable}
          alt='Live from space album cover'
        />

        <CardMedia
          className='store-search-card__logo'
          component='img'
          image={logo ? logo.url : LogoNotAvailable}
          alt='Live from space album cover'
        />
      </Box>

      <Box className='store-search-card__body'>
        <CardContent className='store-search-card__content'>
          <Typography className='store-search-card__name pb-1' variant='h6'>
            {name}
          </Typography>

          {address && (
            <Typography
              className='store-search-card__address'
              variant='body2'
              component='address'
            >
              {address}
            </Typography>
          )}
          {address && (
            <Typography
              className='store-search-card__address'
              variant='body2'
              component='address'
            >
              {getShipmentAddress()}
            </Typography>
          )}
        </CardContent>
      </Box>

      <Box className='store-search-card__meta '>
        {published && (
          <Chip
            label='On Bluon'
            color='primary'
            size='small'
            icon={<CheckCircleIcon />}
          />
        )}

        {!!distance && (
          <Typography className='store-search-card__distance' variant='caption'>
            {distance.toFixed(2)} miles
          </Typography>
        )}
      </Box>
    </Card>
  );
}
