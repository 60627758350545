import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Avatar,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';

import { TechnicianDetailsCardProps } from './interface';
import {
  useGetTechnicianDetailsQuery,
  useUpdateTechnicianSkillLevelMutation,
} from '../../../features/technician/technicianSlice';
import {
  StateHandlerLoading,
  StateHandlerError,
} from '../StateHandler/StateHandler';
import TechSkillLevel from '../TechSkillLevel/TechSkillLevel';

import { formatPhoneNumber } from '../../utils/functions/phone/format-phone-number';
import {
  updateTechnicianSkillLevelErrorToastProps,
  updateTechnicianSkillLevelSuccessToastProps,
} from '../../utils/toast/toast';

import './TechnicianDetailsCard.scss';
import useExtendedSnackbar from '../../hooks/useExtendedSnackbar/useExtendedSnackbar';

const TechnicianDetailsCard = ({ id }: TechnicianDetailsCardProps) => {
  //  Component hooks
  const { data, isLoading } = useGetTechnicianDetailsQuery(id);
  const [
    updateTechnicianSkillLevel,
    {
      isLoading: skillsLoading,
      isError: skillsError,
      reset: resetUpdateTechnicianSkills,
      isSuccess: skillsSuccess,
    },
  ] = useUpdateTechnicianSkillLevelMutation();
  const [showToast] = useExtendedSnackbar();

  //  Component state
  const [skillLevel, setSkillLevel] = useState<number>(data?.skill_level || 0);

  //  Watching skill levels original changes
  useEffect(() => {
    setSkillLevel(data?.skill_level || 0);
  }, [data?.skill_level]);

  //  Watching skills error to revert changes
  useEffect(() => {
    if (!skillsError) return;
    showToast(updateTechnicianSkillLevelErrorToastProps());
    setSkillLevel(data?.skill_level || 0);
    resetUpdateTechnicianSkills();
  }, [skillsError, data?.skill_level, showToast, resetUpdateTechnicianSkills]);

  //  Watching skills success
  useEffect(() => {
    if (!skillsSuccess) return;
    showToast(updateTechnicianSkillLevelSuccessToastProps());
    resetUpdateTechnicianSkills();
  }, [skillsSuccess, showToast, resetUpdateTechnicianSkills]);

  const onSkillLevelChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number | null,
  ) => {
    if (skillsLoading || newValue === null || skillLevel === newValue) return;
    setSkillLevel(newValue || 0);
    updateTechnicianSkillLevel({ id, skill_level: newValue || 0 });
  };

  //  Render functions
  const renderTechnicianInfo = (label = '', value = '', textAlign: string) => {
    return (
      <Grid
        item
        xs={12}
        textAlign={textAlign as Pick<CSSProperties, 'textAlign'>}
        direction='row'
      >
        <Typography className='technician-detail-label' variant='body1'>
          {label}
        </Typography>
        <Typography className='technician-detail-value' variant='body1'>
          {value}
        </Typography>
      </Grid>
    );
  };

  const renderBio = () => {
    if (!data?.bio) return null;

    return (
      <Typography variant='body1' className='technician-bio'>
        {data?.bio}
      </Typography>
    );
  };

  const renderHeader = () => {
    return (
      <div className='technician-details-header'>
        <Typography variant='h4'>{`${data?.first_name} ${data?.last_name}`}</Typography>
        <Typography variant='body1' className='technician-email'>
          {data?.email}
        </Typography>
        <TechSkillLevel
          name='skill-rating'
          size='large'
          value={skillLevel}
          className='tech-skill-level'
          onChange={onSkillLevelChange}
        />
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <Grid
        className='technician-details'
        container
        columnSpacing={1}
        rowSpacing={2}
      >
        {renderTechnicianInfo('Job Title: ', data?.job_title || '', 'start')}
        {renderTechnicianInfo(
          'Phone Number:',
          data?.phone ? formatPhoneNumber(data?.phone) : '',
          'start',
        )}
        {renderTechnicianInfo(
          'Area:',
          [data?.state?.name, data?.country?.name].filter((i) => i).join(', '),
          'start',
        )}
        {renderTechnicianInfo(
          'Bluon Member Since:',
          data?.member_since
            ? new Date(data?.member_since).toLocaleDateString('en-US')
            : '',
          'start',
        )}
      </Grid>
    );
  };

  //  Loading state and error state
  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  //  Rendering
  return (
    <Box>
      <Card raised={false} elevation={0} className='technician-details-card'>
        <div className='technician-image-container'>
          <Avatar
            src={data?.avatar?.url}
            alt={`${data?.first_name} avatar`}
            sx={{ width: 250, height: 250, fontSize: 96 }}
          >
            {data?.first_name[0]}
            {data?.last_name[0]}
          </Avatar>
        </div>
        <CardContent className='technician-details-content'>
          {renderHeader()}
          <div className='technician-details-container'>
            {renderBio()}
            {renderDetails()}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TechnicianDetailsCard;
