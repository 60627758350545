// Components
import StateHandlerLoading from '../../../../common/components/StateHandler/components/StateHandlerLoading/StateHandlerLoading';
import CompanyConfirmTechsForm from '../../../../common/components/CompanyConfirmTechsForm/CompanyConfirmTechsForm';

// Files
import { useGetCompanyQuery } from '../../../../features/company/companySlice';
import { StateHandlerError } from '../../../../common/components/StateHandler/StateHandler';

const OnboardingStepFour = () => {
  const { data, isLoading } = useGetCompanyQuery();

  if (isLoading) return <StateHandlerLoading />;
  if (!data) return <StateHandlerError />;

  return <CompanyConfirmTechsForm data={data.company_users} />;
};

export default OnboardingStepFour;
