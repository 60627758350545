// Libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Typography, Box, DialogActions } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

// Components
import PhoneFieldInput from '../PhoneFieldInput/PhoneFieldInput';
import { AddTechniciansValidationSchema } from './validations/AddTechniciansValidationSchema';

// Files
import { TechniciansAddFormProps } from './interface';
import { useAddTechnicianInvitationMutation } from '../../../features/technician-invitation/technicianInvitationSlice';

import {
  addTechnicianSuccessToastProps,
  addTechnicianErrorToastProps,
  addTechnicianSuccessWithUserToastProps,
} from '../../utils/toast/toast';
import useExtendedSnackbar from '../../hooks/useExtendedSnackbar/useExtendedSnackbar';
import { AddTechnicianInvitationFormData } from '../../../features/technician-invitation/interface';

// Scss
import './TechniciansAddForm.scss';
import { TECHNICIAN_CREATE_ERROR_MESSAGE } from '../../constants/toastConfig';

const DEFAULT_VALUES = {
  phone: '',
  country_code: '1',
};

const TechniciansAddForm = ({ onClose }: TechniciansAddFormProps) => {
  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(AddTechniciansValidationSchema),
    mode: 'onBlur',
  });
  const [showToast] = useExtendedSnackbar();

  const {
    reset: resetForm,
    handleSubmit,
    formState: { isValid },
  } = methods;
  const [addTechnician, { isLoading, reset: resetMutation }] =
    useAddTechnicianInvitationMutation();

  const handleOnSubmit = (data: AddTechnicianInvitationFormData) => {
    addTechnician(data)
      .unwrap()
      .then((res) => {
        // Show success toast
        showToast(
          res.phone.user?.name
            ? addTechnicianSuccessWithUserToastProps(res.phone.user.name)
            : addTechnicianSuccessToastProps(),
        );

        // Clear and reset the form for the next potential submit
        resetMutation();
        resetForm();
      })
      .catch((err) => {
        const message = err?.data?.errors?.phone[0];

        showToast(
          addTechnicianErrorToastProps(
            message ?? TECHNICIAN_CREATE_ERROR_MESSAGE,
          ),
        );
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        className='technicians-add-form'
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Box className='technicians-add-form__body'>
          <PhoneFieldInput
            fullWidth
            className='technicians-add-form__field'
            variant='outlined'
            label='New Technician Phone Number'
            name='phone'
            helperText='Enter the mobile phone number of the technician you would like to invite to your Company.'
          />
        </Box>

        <Typography
          className='technicians-add-form__entry'
          align='center'
          variant='body2'
        >
          <strong>Please Note:</strong> This will send a text message to
          whichever phone number you enter. From there your tech would have to
          continue the process of setting up their account on the Bluon App if
          they don’t have an account already.
        </Typography>

        <DialogActions>
          <Button
            disabled={isLoading}
            variant='outlined'
            color='primary'
            onClick={onClose}
          >
            CANCEL
          </Button>

          <Button
            disabled={!isValid || isLoading}
            startIcon={<SendIcon />}
            variant='contained'
            color='primary'
            type='submit'
          >
            {isLoading ? 'LOADING...' : 'SEND LINK'}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default TechniciansAddForm;
