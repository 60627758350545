
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountSetup, AccountSetupState, ACCOUNT_SETUP_CODE } from './interface';

const initialState: AccountSetupState = {
  email: '',
  code: ACCOUNT_SETUP_CODE.EMPTY,
};

const accountSetupSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCheckAccountSetupResult: (
      state,
      { payload: { email, code } }: PayloadAction<AccountSetup>,
    ) => {
      state.email = email;
      state.code = code;
    },
    resetAccountSetup: (state) => {
      state.email = initialState.email;
      state.code = initialState.code;
    },
  },
});

export const { setCheckAccountSetupResult, resetAccountSetup } = accountSetupSlice.actions;

export default accountSetupSlice.reducer;
