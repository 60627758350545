// Files
import bluonContractorLogo from '../../../assets/images/bluon-contractor-logo.svg';
import bluonContractorWelcome from '../../../assets/images/bluon-contractor-welcome.svg';

// scss
import './LoginLayout.scss';

const LoginBanner = () => {
  return (
    <div className='login-layout'>
      <div className='login-layout-container'>
        <div className='login-layout-text'>
          <h2>Welcome Contractors!</h2>
          <img src={bluonContractorLogo} alt='logo' />
        </div>

        <img src={bluonContractorWelcome} alt='welcome' />
      </div>
    </div>
  );
};

export default LoginBanner;
