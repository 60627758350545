// Libraries
import { Button } from '@mui/material';

// Files
import { ButtonLinkProps } from './interface';

// scss
import './ButtonLink.scss';

const ButtonLink = ({ className, children, ...rest }: ButtonLinkProps) => {
  return (
    <Button disableRipple className={`${className} button-link`} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonLink;
