import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthPersistCredentialState,
  PersistState,
  setUserDataPayload,
} from './interface';
import companyApi from '../company/companySlice';

export const initialState: PersistState = {
  userId: '',
  token: '',
  userData: null,
};

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { userId, token } }: PayloadAction<AuthPersistCredentialState>,
    ) => {
      state.userId = userId;
      state.token = token;
    },
    setUserData: (
      state,
      { payload: { verifiedAt } }: PayloadAction<setUserDataPayload>,
    ) => {
      state.userData = {
        verifiedAt,
      };
    },
    clearCredentials: () => initialState,
  },
  extraReducers(builder) {
    builder.addMatcher(
      companyApi.endpoints.verifyCompany.matchFulfilled,
      (state, { payload }) => {
        state.userData = {
          verifiedAt: payload.data.verified_at,
        };
      },
    );
  },
});

export const { setCredentials, clearCredentials, setUserData } =
  persistSlice.actions;

export default persistSlice.reducer;
