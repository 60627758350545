// Components
import AccountAlreadySetup from '../../common/components/AccountAlreadySetup/AccountAlreadySetup';
import AccountSetupForm from '../../common/components/AccountSetupForm/AccountSetupForm';
import AccountSetupSuccess from '../../common/components/AccountSetupSuccess/AccountSetupSuccess';
import { EmailNotInSystem } from '../../common/components/EmailNotInSystem/EmailNotInSystem';
import { useAppSelector } from '../../config/redux/hooks';
import { ACCOUNT_SETUP_CODE } from '../../features/account-setup/interface';

const AccountSetup = () => {
  const code = useAppSelector((state) => state.accountSetup.code);

  const render = (statusCode: ACCOUNT_SETUP_CODE | undefined) => {
    switch (statusCode) {
      case ACCOUNT_SETUP_CODE.NOT_EXISTS:
        return <EmailNotInSystem />;
      case ACCOUNT_SETUP_CODE.ALREADY_SETUP:
        return <AccountAlreadySetup />;
      case ACCOUNT_SETUP_CODE.SUCCESS:
        return <AccountSetupSuccess />;
      default:
        return <AccountSetupForm />;
    }
  };

  return <>{render(code)}</>;
};

export default AccountSetup;
