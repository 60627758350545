import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Checkbox from '../Checkbox/Checkbox';

// Files
import { CompanyTermsFormProps } from './interface';
import { CompanyTermsFormValidationSchema } from './validations/CompanyTermsFormValidationSchema';
import busImg from './../../../assets/images/bus.png';

// Scss
import './CompanyTermsForm.scss';

const DEFAULT_VALUES = {
  bluon_check_1: false,
  bluon_check_2: false,
  bluon_check_3: false,
  bluon_check_4: false,
};

const FIELD_LABELS = [
  {
    name: 'bluon_check_1',
    label:
      'There is no direct monthly fee to use 24/7 Tech Support for my techs.',
  },
  {
    name: 'bluon_check_2',
    label:
      'That we will use the Bluon platform to request parts and materials from our local suppliers.',
  },
  {
    name: 'bluon_check_3',
    label: 'Our use of Bluon’s platform is how Bluon gets paid.',
  },
  {
    name: 'bluon_check_4',
    label:
      'If we do not use Bluon to order 50% or more of our parts & materials, then we may lose access.',
  },
];

const CompanyTermsForm = ({ onSuccess }: CompanyTermsFormProps) => {
  const methods = useForm({
    resolver: yupResolver(CompanyTermsFormValidationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <Box className='onboarding-step-form-container onboarding-step-one'>
      <FormProvider {...methods}>
        <form
          className='company-terms-form'
          action=''
          onSubmit={handleSubmit(onSuccess)}
        >
          <Box className='onboarding-step-form'>
            <figure className='onboarding-step-one__image'>
              <img src={busImg} alt='Bluon bus' />
            </figure>
            <Typography
              className='company-terms-form__legend'
              component='legend'
              variant='h5'
              color='primary'
            >
              I Acknowledge:
            </Typography>

            <FormGroup className='company-terms-form__body'>
              <Stack divider={<Divider flexItem />} spacing={2}>
                {FIELD_LABELS.map(({ label, name }) => (
                  <Box key={name}>
                    <FormControlLabel
                      control={<Checkbox name={name} />}
                      label={label}
                    />
                  </Box>
                ))}
              </Stack>
            </FormGroup>
          </Box>

          <Box className='onboarding-step-actions'>
            <Button type='submit' variant='contained' disabled={!isValid}>
              Go To step 2
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CompanyTermsForm;
