import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import {
  TechnicianInvitation,
  ListTechnicianInvitationRequest,
  TechnicianInvitationResponse,
  UpdateTechnicianInvitationRequest,
  UpdateTechnicianInvitationResponse,
  AddTechnicianInvitationResponse,
  AddTechnicianInvitationFormData,
} from './interface';

export const technicianApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    addTechnicianInvitation: build.mutation<
      AddTechnicianInvitationResponse,
      AddTechnicianInvitationFormData
    >({
      query: (data: AddTechnicianInvitationFormData) => {
        return {
          url: '/technician-invitations',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => [
        { type: 'Technician', id: 'PARTIAL-LIST' },
        { type: 'Technician Invitation', id: 'PARTIAL-LIST' },
      ],
      transformResponse: (response: { data: TechnicianInvitation }) =>
        response.data,
    }),
    updateInvitation: build.mutation<
      UpdateTechnicianInvitationResponse,
      UpdateTechnicianInvitationRequest
    >({
      query: (data: UpdateTechnicianInvitationRequest) => {
        return {
          url: '/technician-invitations/update',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => [
        { type: 'Technician', id: 'PARTIAL-LIST' },
        { type: 'Technician Invitation', id: 'PARTIAL-LIST' },
      ],
    }),
    getTechnicianInvitationList: build.query<
      TechnicianInvitationResponse,
      ListTechnicianInvitationRequest
    >({
      query: (params) => {
        return {
          url: '/technician-invitations',
          method: 'GET',
          params,
        };
      },
      providesTags: () => [
        { type: 'Technician Invitation', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
});

export const {
  useAddTechnicianInvitationMutation,
  useGetTechnicianInvitationListQuery,
  useUpdateInvitationMutation,
} = technicianApi;
