// Libraries
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { FormProvider, useForm, FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

// Components
import SelectFieldInput from '../SelectFieldInput/SelectFieldInput';

// Files
import { storeCardFiltersProps } from './interface';
import { STORE_FILTER_OPTIONS } from '../../constants/constants';

// Scss
import './StoreCardFilters.scss';

const StoreCardFilters = ({ onChange }: storeCardFiltersProps) => {
  const [searchParams] = useSearchParams();
  const methods = useForm({
    defaultValues: {
      filter: searchParams.get('filter') || '',
    },
  });

  const { watch } = methods;

  useEffect(() => {
    const watchAll = watch((data: FieldValues) => {
      const newParams: FieldValues = {};

      for (const property in data) {
        if (data[property]) {
          newParams[property] = data[property];
        }
      }

      onChange(newParams);
    });

    return () => watchAll.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Box className='store-card-filters'>
      <FormProvider {...methods}>
        <form>
          <SelectFieldInput
            fullwidth
            name='filter'
            label='Filter by'
            options={STORE_FILTER_OPTIONS}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default StoreCardFilters;
