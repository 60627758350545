// Libraries
import { Box, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

// Files
import { PhoneFieldInputProps } from './interface';
import {
  formatPhoneNumber,
  normalizePhoneNumber,
} from '../../utils/functions/phone/format-phone-number';

const PhoneFieldInput = ({
  name,
  helperText,
  adornment,
  className,
  ...rest
}: PhoneFieldInputProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: '',
  });

  // Handles how value is displayed in the field
  const transformInput = (val: string) => formatPhoneNumber(val);

  // Keeps value normalized
  const transformOutput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => normalizePhoneNumber(e.target.value);

  return (
    <Box className={className}>
      <TextField
        autoComplete='off'
        value={transformInput(value)}
        onChange={(e) => onChange(transformOutput(e))}
        onBlur={onBlur}
        error={!!error}
        helperText={error?.message}
        InputProps={{
          endAdornment: adornment,
        }}
        {...rest}
      />
      <div className='mt-2 text-xs text-gray-500'>{helperText}</div>
    </Box>
  );
};

export default PhoneFieldInput;
