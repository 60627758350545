// Libraries
import { InputAdornment } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

// Files
import { ErrorAdornmentProps } from './interface';

const ErrorAdornment = ({ error }: ErrorAdornmentProps) => {
  return (
    <InputAdornment position='end'>
      <ErrorIcon color='warning' sx={{ display: error ? 'initial' : 'none' }} />
    </InputAdornment>
  );
};

export default ErrorAdornment;
