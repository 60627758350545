// Libraries
import { Box, Typography } from '@mui/material';

// Components
import StoreAddDialog from '../../../common/components/StoreAddDialog/StoreAddDialog';
import StoreCardFilters from '../../../common/components/StoreCardFilters/StoreCardFilters';
import { storesTopBarProps } from './interface';

const StoresTopBar = ({ onChange, shouldHideFilters }: storesTopBarProps) => {
  return (
    <Box className='flex h-[100px] w-full items-center justify-between'>
      <Box className='flex items-center gap-4'>
        <Box>
          <Typography variant='h4'>Your Stores</Typography>
        </Box>
        {!shouldHideFilters && (
          <Box className='stores__filter'>
            <StoreCardFilters onChange={onChange} />
          </Box>
        )}
      </Box>
      <Box>
        <StoreAddDialog />
      </Box>
    </Box>
  );
};

export default StoresTopBar;
