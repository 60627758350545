// Libraries
import { InputLabel, FormHelperText, FormControl } from '@mui/material';
import { Box } from '@mui/system';
import { useController, useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Components
import ErrorAdornment from '../ErrorAdornment/ErrorAdornment';

// Files
import { SelectFieldInputProps } from './interface';

// scss
import './SelectFieldInput.scss';

const SelectFieldInput = ({
  size,
  name,
  label,
  adornment,
  options = [],
  fullwidth = false,
}: SelectFieldInputProps) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  if (!adornment) {
    adornment = error ? <ErrorAdornment error={!!error} /> : <></>;
  }

  return (
    <Box className='select-input'>
      <FormControl size={size} fullWidth={fullwidth}>
        <InputLabel
          id={`input-select-${name}-label`}
          className='select-input-label SelectFieldInputLabel'
        >
          {label}
        </InputLabel>

        <Select
          labelId={`input-select-${name}-label`}
          id={`input-select-${name}`}
          fullWidth
          endAdornment={adornment}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          error={!!error}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {error?.message && (
        <FormHelperText>{error?.message || ' '}</FormHelperText>
      )}
    </Box>
  );
};

export default SelectFieldInput;
