import * as Yup from 'yup';
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_PHONE_NUMBER,
} from '../../../constants/validationStrings';

export const AddTechniciansValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required(REQUIRED_PHONE_NUMBER)
    .matches(/^[0-9]+$/, INVALID_PHONE_NUMBER)
    .min(10, INVALID_PHONE_NUMBER)
    .max(10, INVALID_PHONE_NUMBER),
});
