// Libraries
import { useState, Fragment } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Files
import TechniciansAddForm from '../TechniciansAddForm/TechniciansAddForm';

import { useAppDispatch } from '../../../config/redux/hooks';
import { technicianApi } from '../../../features/technician/technicianSlice';
import { technicianApi as technicianInvitationApi } from '../../../features/technician-invitation/technicianInvitationSlice';

// Scss
import './TechniciansAddModal.scss';
import RoundedButton from '../RoundedButton';

const TechniciansAddModal = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    //  Resetting technician list
    dispatch(
      technicianApi.util.invalidateTags([
        { type: 'Technician', id: 'PARTIAL-LIST' },
      ]),
    );

    //  Resetting technician invitation list
    dispatch(
      technicianInvitationApi.util.invalidateTags([
        { type: 'Technician Invitation', id: 'PARTIAL-LIST' },
      ]),
    );
  };

  return (
    <Fragment>
      <RoundedButton
        variant='outlined'
        color='primary'
        size='small'
        endIcon={<AddIcon />}
        onClick={handleOpen}
      >
        ADD TECHNICIAN
      </RoundedButton>

      <Dialog
        open={open}
        className='technicians-add-modal'
        onClose={handleClose}
      >
        {handleClose ? (
          <IconButton
            className='technicians-add-modal__close-btn'
            size='small'
            aria-label='close'
            onClick={handleClose}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        ) : null}
        <DialogTitle align='center'>Add Technician</DialogTitle>

        <DialogContent>
          <TechniciansAddForm onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default TechniciansAddModal;
