// Libraries
import { useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';

import {
  useLazyListNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from '../../../features/notifications/toasts/toastSlice';
import { StyledAlertProps } from '../../components/StyledAlert/interface';
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';
import { NotificationsNode, database } from '../../../config/firebase';
import { useGetCompanyQuery } from '../../../features/company/companySlice';

const useServerSnackbar = (): void => {
  const [notifications, setNotifications] = useState<string[]>([]);
  const [timeStamp, setTimeStamp] = useState<string>('');
  const [showToast, closeToast] = useExtendedSnackbar();
  const [getNotifications] = useLazyListNotificationsQuery();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const { data } = useGetCompanyQuery();

  const handleMarkNotificationRead = (
    serverId: string,
    toastId: string | number,
  ) => {
    markNotificationAsRead(serverId)
      .unwrap()
      .then(() => {
        closeToast(toastId);
        const index = notifications.indexOf(serverId);
        if (index > -1) setNotifications(notifications.splice(index, 1));
      })
      .catch(() => {
        // TODO Think of better way to handle any potential errors
        console.log('Something went wrong...');
      });
  };

  const handleGetNotifications = () => {
    getNotifications('', false)
      .unwrap()
      .then((results) => {
        results.map((result) => {
          if (result.read) return;

          const isOpen = notifications.find((not) => not === result.id);
          if (!isOpen) {
            const toastProps: StyledAlertProps = {
              title: result.title,
              message: result.message.replace('/n', '<br/>'),
              severity: result.severity,
              persist: true,
              onActionClick(id) {
                handleMarkNotificationRead(result.id, id);
              },
            };
            setNotifications([...notifications, result.id]);
            showToast(toastProps);
          }
        });
      });
  };

  useEffect(() => {
    if (timeStamp) handleGetNotifications();
  }, [timeStamp]);

  useEffect(() => {
    if (!data?.id) return;

    if (NotificationsNode) {
      const countersRef = ref(database, NotificationsNode + data?.id);
      onValue(countersRef, (snapshot) => {
        setTimeStamp(snapshot.val());
      });
    }

    return () => {
      closeToast();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);
};

export default useServerSnackbar;
