import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../config/redux/hooks';
import { resetLoginAttempt } from '../../../features/auth/authSlice';

import './LoginLocked.scss';

const LoginLocked = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClick = () => {
    dispatch(resetLoginAttempt());
    navigate('/forgot-password');
  };

  return (
    <Box className='login-locked'>
      <Grid className='login-locked-grid' item xs={8}>
        <Typography variant='h4'>Your Account has been Locked</Typography>
        <Typography className='login-locked-message' variant='h6'>
          Your account has been locked due to an excessive amount of
          unsuccessful login attempts. Your account will automatically unlock in
          10 minutes or you can unlock it now by resetting your password.
        </Typography>
        <Button fullWidth variant='contained' onClick={handleOnClick}>
          Reset Password
        </Button>
      </Grid>
    </Box>
  );
};

export default LoginLocked;
