// Libraries
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { useModal, create as CreateNiceModal } from '@ebay/nice-modal-react';

// Components
import StyledDialog from '../StyledDialog/StyledDialog';

// Files
import { UpcomingFeatureModalProps } from './interface';

// Scss
import './UpcomingFeatureModal.scss';
export default CreateNiceModal(
  ({ title, subTitle, imageSrc }: UpcomingFeatureModalProps) => {
    // Use a hook to manage the modal state
    const { hide, visible } = useModal();

    return (
      <StyledDialog
        open={visible}
        className='upcoming-feature-modal'
        onClose={hide}
      >
        <DialogTitle component='header'>
          <Typography variant='h2' color='primary'>
            {title}
          </Typography>

          <Typography variant='h4' color='initial'>
            {subTitle}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box
            component='img'
            sx={{
              display: 'block',
              width: '100%',
            }}
            alt='The house from the offer.'
            src={imageSrc}
          />
        </DialogContent>

        <DialogActions>
          <Button size='large' variant='contained' onClick={hide}>
            Got it!
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  },
);
