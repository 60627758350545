/**
 * External Dependencies
 */
import { useCallback, useState } from 'react';
import { Grid, Box } from '@mui/material';

// Components
import TechniciansTopBar from './Partials/TechniciansTopBar';
import TechniciansList from './../../common/components/TechniciansList/TechniciansList';
import TechnicianInvitationSidebar from '../../common/components/TechnicianInvitationSidebar/TechnicianInvitationSidebar';
import StateHandler from '../../common/components/StateHandler/StateHandler';
import StateHandlerEmpty from '../../common/components/StateHandlerEmpty/StateHandlerEmpty';

// Files
import { useGetTechnicianListQuery } from '../../features/technician/technicianSlice';

// Scss
import './Technicians.scss';
import { useGetTechnicianInvitationListQuery } from '../../features/technician-invitation/technicianInvitationSlice';
import useInfiniteScrollQuery from '../../common/hooks/useInfiniteScrollQuery/useInfiniteScrollQuery';

const Technicians = () => {
  const [search, setSearch] = useState('');

  const {
    combinedData,
    refresh,
    hasNextPage,
    isLoading,
    loadMore,
    isFetching,
  } = useInfiniteScrollQuery(useGetTechnicianListQuery, {
    search_string: search,
  });

  const {
    combinedData: invitations,
    hasNextPage: invHasNextPage,
    isLoading: isLoadingInvitations,
    isFetching: isFetchingInvs,
    loadMore: loadMoreInvs,
  } = useInfiniteScrollQuery(useGetTechnicianInvitationListQuery, {
    status: 'pending',
  });

  const handleSearch = useCallback((value: string) => {
    refresh();
    setSearch(value);
  }, []);

  const loadNextPage = () => {
    if (hasNextPage) loadMore();
  };

  const loadNextInvsPage = () => {
    if (isFetchingInvs || isLoadingInvitations || !invHasNextPage) return;
    loadMoreInvs();
  };

  const hasInvitations = !!invitations?.length;

  return (
    <Box className='techs-container '>
      <Grid item container xs={12} className='header-container'>
        <TechniciansTopBar onSearch={handleSearch} />
      </Grid>

      <Grid container spacing={2} className='content-container'>
        <Grid item xs={hasInvitations && !isLoading ? 9 : 12}>
          <StateHandler
            isLoading={isLoading}
            isError={combinedData === null}
            isEmpty={combinedData?.length === 0}
            emptyComponent={
              <StateHandlerEmpty
                title={
                  search.length
                    ? 'No technicians match your criteria.'
                    : 'You haven’t added any technicians.'
                }
              />
            }
          >
            {combinedData && (
              <TechniciansList
                data={combinedData}
                hasInvitations={hasInvitations}
                hasNextPage={hasNextPage}
                isLoading={isLoading || isFetching}
                loadMore={loadNextPage}
              />
            )}
          </StateHandler>
        </Grid>

        {hasInvitations && !isLoading && (
          <Grid item xs={3} height='100%'>
            <TechnicianInvitationSidebar
              invitations={invitations}
              isLoading={isLoadingInvitations}
              hasNextPage={invHasNextPage}
              onLoadMore={loadNextInvsPage}
              isFetching={isFetchingInvs}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Technicians;
