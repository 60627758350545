// Libraries
import { Typography, Box, Button, Stack } from '@mui/material';

// Files
import { StateHandlerEmptyProps } from './interface';

// Scss
import './StateHandlerEmpty.scss';

const StateHandlerEmpty = ({
  title,
  text,
  image,
  btnLabel,
  onBtnClick = () => {},
}: StateHandlerEmptyProps) => {
  return (
    <Stack className='state-handler-empty' spacing={5}>
      {image && (
        <Box className='state-handler-empty__image'>
          <img src={image} alt='Not Found' />
        </Box>
      )}

      <Box>
        {title && (
          <Typography className='state-handler-empty__title' variant='h5'>
            {title}
          </Typography>
        )}

        {text && (
          <Typography className='state-handler-empty__text'>{text}</Typography>
        )}
      </Box>

      <Box>
        {btnLabel && (
          <Button variant='contained' color='primary' onClick={onBtnClick}>
            {btnLabel}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default StateHandlerEmpty;
