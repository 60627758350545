import { Box, CircularProgress, Typography } from '@mui/material';
import TechnicianInvitationCard from '../TechnicianInvitationCard/TechnicianInvitationCard';
import { TechnicianInvitationSidebarProps } from './interface';

// Scss
import './TechnicianInvitationSidebar.scss';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const TechnicianInvitationSidebar = ({
  show = true,
  invitations,
  isLoading,
  hasNextPage,
  onLoadMore,
  isFetching,
}: TechnicianInvitationSidebarProps) => {
  if (!show) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    rootMargin: '0px 0px 0px 0px',
  });

  return (
    <Box className='technician-invitation-sidebar'>
      <Typography variant='h6'>Waiting for Approval:</Typography>

      {invitations.map((item, index) => (
        <TechnicianInvitationCard key={index} data={item} />
      ))}

      {(isLoading || hasNextPage || isFetching) && (
        <Box
          display='flex'
          justifyContent='center'
          padding='1rem'
          ref={sentryRef}
        >
          <CircularProgress size='1.5rem' />
        </Box>
      )}
    </Box>
  );
};

export default TechnicianInvitationSidebar;
