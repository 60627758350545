export const truncateString = (str:string, maxLength:number) => {
    if (str.length <= maxLength) {
      return str;
    }

    const midpoint = Math.floor(str.length / 2);
    const leftSideLength = Math.floor((maxLength - 3) / 2);
    const rightSideLength = Math.ceil((maxLength - 3) / 2);

    const leftSide = str.slice(0, midpoint - leftSideLength);
    const rightSide = str.slice(midpoint + rightSideLength);

    return leftSide + '...' + rightSide;
  }
