import { Checkbox as MuiCheckbox } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { CheckboxProps } from './interface';

const Checkbox = ({ name, ...props }: CheckboxProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  return <MuiCheckbox onChange={onChange} checked={value} {...props} />;
};

export default Checkbox;
