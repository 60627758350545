// Scss
import './GoogleMapMarker.scss';

// Files
import { GoogleMapMarkerProps } from './interface';

const GoogleMapMarker = ({ lat, lng }: GoogleMapMarkerProps) => {
  return (
    <svg
      className='GoogleMapMarker'
      width='28'
      height='40'
      viewBox='0 0 28 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-lat={lat}
      data-lng={lng}
    >
      <path
        d='M14 0C6.26 0 0 6.26 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.26 21.74 0 14 0ZM14 19C11.24 19 9 16.76 9 14C9 11.24 11.24 9 14 9C16.76 9 19 11.24 19 14C19 16.76 16.76 19 14 19Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default GoogleMapMarker;
