import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './styles.scss';
import { BackButtonAction } from './interface';

export const BackButton = ({ action, title }: BackButtonAction) => {
  const navigate = useNavigate();

  return (
    <Button
      variant='text'
      className='go-back-button'
      color='primary'
      onClick={() => (action ? action() : navigate(-1))}
      startIcon={<ArrowBackIcon />}
    >
      {title ? title : 'Back'}
    </Button>
  );
};
