// Libraries
import { useNavigate } from 'react-router-dom';
import { useState, useMemo, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  DialogContentText,
} from '@mui/material';

// Components
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import PhoneFieldInput from '../PhoneFieldInput/PhoneFieldInput';
import StoreDeleteForm from '../StoreDeleteForm/StoreDeleteForm';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

// Files
import { StoreUpdateFormProps } from './interface';
import { useUpdateStore } from '../../hooks/useUpdateStore/useUpdateStore';
import { useBlockDirtyNavigation } from '../../hooks/useBlockDirtyNavigation/useBlockDirtyNavigation';

// Scss
import './StoreUpdateForm.scss';

const StoreUpdateForm = ({
  id,
  title,
  text,
  name,
  companySupplier,
  hideDeleteButton = false,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Save Changes',
  onClose,
  setDialogCloseFunction,
}: StoreUpdateFormProps) => {
  const defaultValues = useMemo(
    () => ({
      account_number: companySupplier?.account_number || '',
      sales_rep_name: companySupplier?.sales_rep_name || '',
      sales_rep_phone: companySupplier?.sales_rep_phone || '',
      sales_rep_email: companySupplier?.sales_rep_email || '',
    }),
    [companySupplier],
  );
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [watchCancelButton, setWatchCancelButton] = useState(false);
  const { updateStore, methods } = useUpdateStore({
    id,
    name,
    defaultValues,
    onSuccess: () => {
      handleCloseConfirm();
      onClose(false);
    },
  });
  const {
    reset,
    formState: { isDirty, isValid },
  } = methods;

  useBlockDirtyNavigation({
    isDirty,
    setUrl,
    openDialog: setConfirmVisible,
    extraButtons: [watchCancelButton],
  });

  // Methods
  const handleCloseConfirm = () => {
    setWatchCancelButton(!watchCancelButton);
    setConfirmVisible(false);
  };
  const handleShowConfirm = () => {
    setConfirmVisible(true);
  };

  const handleClose = () => {
    isDirty ? handleShowConfirm() : handleDiscard();
  };

  const handleConfirm = () => {
    updateStore();
    setConfirmVisible(false);
    onClose(false);
    navigate(url);
  };

  const handleDiscard = () => {
    reset(defaultValues);
    setConfirmVisible(false);
    navigate(url);
    onClose(false);
  };

  useEffect(() => {
    setDialogCloseFunction(() => handleClose);

    return () => {
      setDialogCloseFunction(() => () => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <FormProvider {...methods}>
      <DialogTitle className='store-update-form-title' component='div'>
        <Typography variant='h5'>{title}</Typography>

        {text && <DialogContentText>{text}</DialogContentText>}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={updateStore}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldInput
                fullWidth
                name='account_number'
                label='Account Number'
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldInput
                fullWidth
                name='sales_rep_name'
                label='Sales Rep Name'
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneFieldInput
                fullWidth
                name='sales_rep_phone'
                label='Rep Phone'
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldInput
                fullWidth
                name='sales_rep_email'
                label='Rep Email'
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions className='store-update-form-actions'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <Button variant='outlined' onClick={handleClose}>
              {cancelButtonLabel}
            </Button>
          </Grid>

          <Grid item xs='auto'>
            <Button
              variant='contained'
              disabled={!isValid || !isDirty}
              onClick={updateStore}
            >
              {confirmButtonLabel}
            </Button>
          </Grid>

          {!hideDeleteButton && (
            <Grid item xs={12}>
              <StoreDeleteForm id={id} name={name} />
            </Grid>
          )}
        </Grid>
      </DialogActions>

      <ConfirmationDialog
        showDiscardButton
        open={confirmVisible}
        color='primary'
        title='Unsaved changes'
        content='You are about to discard your unsaved changes.'
        confirmButtonText='Save'
        onClose={handleCloseConfirm}
        onDiscard={handleDiscard}
        onConfirm={handleConfirm}
      />
    </FormProvider>
  );
};

export default StoreUpdateForm;
