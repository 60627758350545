// Libraries
import { useMemo, useEffect } from 'react';
import { Typography, Link, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import Stepper from '../../common/components/Stepper/Stepper';
import OnboardingStepOne from './partials/OnboardingStepOne/OnboardingStepOne';
import OnboardingStepTwo from './partials/OnboardingStepTwo/OnboardingStepTwo';
import OnboardingStepThree from './partials/OnboardingStepThree/OnboardingStepThree';
import OnboardingStepFour from './partials/OnboardingStepFour/OnboardingStepFour';
import OnboardingVideoHighlight from './partials/OnboardingVideoHighlight/OnboardingVideoHighlight';
import OnboardingImageHighlight from './partials/OnboardingImageHighlight/OnboardingImageHighlight';

// Files
import { OnboardingStep } from './interface';
import useStep from '../../common/hooks/useStep/useStep';

// Scss
import './Onboarding.scss';
import { BackButton } from '../../common/components/BackButton';
import { AuthPersistCredentialState } from '../../features/persist/interface';
import { axiosApi } from '../../config/rtk/axiosApi/axiosApi';
import { setCredentials } from '../../features/persist/persistSlice';

const Onboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = useMemo<OnboardingStep[]>(
    () => [
      {
        header: {
          title: 'Step 1',
          subTitle: 'See how it works',
          text: 'Welcome to Bluon for Contractors! We will get you up and running in 4 quick steps – no BS!',
        },
        component: OnboardingStepOne,
        highlight: {
          type: 'video',
          props: {
            src: 'https://bluon.com/clp/assets/videos/TheBluonBargain.mp4',
            title: 'Bluon For Contractors, explained.',
            subTitle: 'No cost to YOU! Find out how we do it. ',
            caption: (
              <>
                Questions? Call us anytime!{' '}
                <Link underline='hover' href='tel:8554258686'>
                  (855) 425 - 8686
                </Link>
              </>
            ),
          },
        },
      },
      {
        header: {
          title: 'Step 2',
          subTitle: 'Confirm your information',
          text: 'Make sure we got everything right!',
        },
        component: OnboardingStepTwo,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-1.png'),
            title: 'You Can Easily Edit Your Info Later!',
            subTitle: 'Just click on the settings. ',
          },
        },
      },
      {
        header: {
          title: 'Step 3',
          subTitle: 'Upload your company logo (If you want)',
          text: 'Your logo is used across the entire platform – You can change it any time.',
        },
        component: OnboardingStepThree,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-2.png'),
            title: 'New Logo? Forgot to Add One? No problem.',
            subTitle: 'Update your logo any time in your account settings.',
          },
        },
      },
      {
        header: {
          title: 'Step 4',
          subTitle: 'Connect with your techs!',
          text: 'Start by confirming those already on Bluon! Then, easily add the rest of your team on the next page.',
        },
        component: OnboardingStepFour,
        highlight: {
          type: 'image',
          props: {
            src: require('../../assets/images/onboarding-highlight-img-3.png'),
            title: 'Easily Add Your Techs.',
            subTitle: 'You’re heading here next!',
          },
        },
      },
    ],
    [],
  );
  const { currentStep, goToNextStep, goToPrevStep } = useStep(steps.length);

  const ActiveForm = useMemo(
    () => steps[currentStep]?.component,
    [currentStep, steps],
  );

  const ActiveHighlight = useMemo(
    () =>
      steps[currentStep]?.highlight.type === 'video'
        ? OnboardingVideoHighlight
        : OnboardingImageHighlight,
    [currentStep, steps],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const logoutAction = () => {
    const logoutCredentials: AuthPersistCredentialState = {
      userId: '',
      token: '',
    };

    dispatch(setCredentials(logoutCredentials));
    dispatch(axiosApi.util.resetApiState());
    navigate('/login');
  };

  return (
    <Box className='onboarding'>
      <Box className='onboarding-step-container'>
        <Box className='onboarding-step-indicator'>
          <div className='onboarding__header'>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h4'>
                {steps[currentStep].header.title}
              </Typography>
              <BackButton
                action={currentStep === 0 ? logoutAction : goToPrevStep}
                title={currentStep === 0 ? 'LOG OUT' : ''}
              />
            </Box>

            <Typography variant='h5'>
              {steps[currentStep].header.subTitle}
            </Typography>

            <Typography className='min-h-[48px]'>
              {steps[currentStep].header.text}
            </Typography>
          </div>

          <Stepper currentStep={currentStep} totalSteps={steps.length} />
        </Box>
        <Box className='onboarding-step-form'>
          <ActiveForm onNextPage={goToNextStep} />
        </Box>
        <Box className='onboarding-step-action'></Box>
      </Box>
      <Box className='onboarding-step-info'>
        <ActiveHighlight {...steps[currentStep]?.highlight.props} />
      </Box>
    </Box>
  );
};

export default Onboarding;
