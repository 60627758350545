import { axiosApi } from '../../../config/rtk/axiosApi/axiosApi';
import { ListNotificationResponse } from './interface';

export const notificationApi = axiosApi.injectEndpoints({
  endpoints: (build) => ({
    listNotifications: build.query<ListNotificationResponse, string>({
      query: () => {
        return {
          url: '/notifications',
          method: 'GET',
        };
      },
      providesTags: () => [{ type: 'Notification', id: 'NOTIFICATION-LIST' }],
      transformResponse: (response: { data: ListNotificationResponse }) =>
        response.data,
    }),
    markNotificationAsRead: build.mutation<boolean, string>({
      query: (id) => {
        return {
          url: `/notifications/${id}/read`,
          method: 'POST',
        };
      },
      invalidatesTags: () => [
        { type: 'Notification', id: 'NOTIFICATION-LIST' },
      ],
    }),
  }),
});

export const {
  useListNotificationsQuery,
  useLazyListNotificationsQuery,
  useMarkNotificationAsReadMutation,
} = notificationApi;
