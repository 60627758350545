import {
  createSearchParams,
  NavigateOptions,
  useNavigate,
} from 'react-router-dom';
import { useNavigateSearchParams } from './interface';

const useNavigateSearch = (options?: NavigateOptions) => {
  const navigate = useNavigate();

  return (pathname: string, params: useNavigateSearchParams) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` }, options);
};

export default useNavigateSearch;
