// Libraries
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Components
import AccountForm from '../../../common/components/AccountForm/AccountForm';
import FileUpload from '../../../common/components/FileUpload/FileUpload';

// Files
import { CompanyUpdateFormProps } from './interface';
import { useBlockDirtyNavigation } from '../../hooks/useBlockDirtyNavigation/useBlockDirtyNavigation';
import useUpdateCompanyForm from '../../../common/hooks/useUpdateCompanyForm/useUpdateCompanyForm';

// Scss
import './CompanyUpdateForm.scss';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const CompanyUpdateForm = ({ data }: CompanyUpdateFormProps) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [watchCancelButton, setWatchCancelButton] = useState(false);

  const navigate = useNavigate();
  const [methods, handleSubmit, { isLoading }] = useUpdateCompanyForm({ data });

  const {
    reset,
    formState: { isDirty, isValid },
  } = methods;

  useBlockDirtyNavigation({
    isDirty,
    setUrl,
    openDialog: setOpen,
    extraButtons: [watchCancelButton],
  });

  const handleDiscard = () => {
    reset();
  };

  const handleDialogOnClose = () => {
    setWatchCancelButton(!watchCancelButton);
    setOpen(false);
  };
  const handleDialogOnConfirm = () => {
    handleSubmit();
    setOpen(false);
    navigate(url);
  };

  const handleDialogOnDiscard = () => {
    reset();
    setOpen(false);
    navigate(url);
  };

  return (
    <FormProvider {...methods}>
      <form className='company-update-form' onSubmit={handleSubmit}>
        <ConfirmationDialog
          open={open}
          color='primary'
          showDiscardButton
          title='Unsaved Changes'
          content='You have unsaved changes. Clicking "Yes" will lose all your changes. Do you still want to continue?'
          disableConfirmBtn={!isValid}
          confirmButtonText='save changes'
          onClose={handleDialogOnClose}
          onDiscard={handleDialogOnDiscard}
          onConfirm={handleDialogOnConfirm}
        />

        <FileUpload
          name='logo'
          title='Company Logo'
          progress={100}
          isUploading={false}
        />

        <AccountForm onDiscard={handleDiscard} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default CompanyUpdateForm;
