// Libraries
import { Box, Typography } from '@mui/material';

// Files
import { StoreSearchEmptyProps } from './interface';

// Scss
import './StoreSearchEmpty.scss';

const StoreSearchEmpty = ({ searchValue }: StoreSearchEmptyProps) => {
  const title = `No results ${searchValue ? 'found for' : ''}`;

  return (
    <Box className='store-search-empty'>
      <Typography variant='h5' mb={2}>
        {title}
      </Typography>

      {searchValue && (
        <Typography variant='h4' color='primary' mb={2}>
          {searchValue}
        </Typography>
      )}

      <Typography>
        Please try to search something else.
        <br />
        Changing the search by field may yield better results.
      </Typography>
    </Box>
  );
};

export default StoreSearchEmpty;
