import { useCallback } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';

// Files
import { StyledAlertProps } from '../../components/StyledAlert/interface';

const useExtendedSnackbar = (): [
  ({
    message,
    title,
    severity,
    persist,
    actionLabel,
    onActionClick,
  }: StyledAlertProps) => void,
  (key?: SnackbarKey | undefined) => void,
] => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToast = useCallback(
    ({
      message,
      title,
      severity,
      persist,
      actionLabel,
      onActionClick,
    }: StyledAlertProps) => {
      enqueueSnackbar(message, {
        variant: 'styledAlert',
        title,
        severity,
        persist,
        actionLabel,
        onActionClick,
      });
    },
    [enqueueSnackbar],
  );

  return [showToast, closeSnackbar];
};

export default useExtendedSnackbar;
