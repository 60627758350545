// Libraries
import { Button, ButtonGroup } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

// Files
import { StoreFilterFormButtonGroupProps } from './interface';

const StoreFilterFormButtonGroup = ({
  name,
  options,
}: StoreFilterFormButtonGroupProps) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  const handleOnClick = (value: string) => {
    onChange(value);
  };

  return (
    <ButtonGroup size='small'>
      {options.map((option) => (
        <Button
          key={option.value}
          variant={option.value === value ? 'contained' : 'outlined'}
          onClick={() => handleOnClick(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default StoreFilterFormButtonGroup;
