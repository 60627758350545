import { useEffect } from 'react';
import { CheckValidTokenData } from '../../../features/token/interface';
import { useCheckValidTokenQuery } from '../../../features/token/tokenSlice';
import { useLoginLoader } from '../../components/LoginLoaderProvider/LoginLoaderProvider';
import useExtendedSnackbar from '../useExtendedSnackbar/useExtendedSnackbar';
import {
  getBrokenURLErrorToastProps,
  getExpiredErrorToastProps,
} from '../../utils/toast/toast';
import { useNavigateSearchParams } from '../useNavigateSearch/interface';
import useNavigateSearch from '../useNavigateSearch/useNavigateSearch';

export const useCheckValidToken = (
  formData: CheckValidTokenData,
  queryParams: useNavigateSearchParams,
) => {
  const { showLoader } = useLoginLoader();
  const [showToast] = useExtendedSnackbar();
  const navigateSearch = useNavigateSearch();

  const { data, isLoading, isError, refetch } =
    useCheckValidTokenQuery(formData);

  useEffect(() => {
    showLoader(isLoading);

    if (isError) {
      const passwordErrorToastProps = getBrokenURLErrorToastProps();
      showToast(passwordErrorToastProps);

      return;
    }

    const isExpired = data?.expired;
    if (isExpired) {
      const expiredLinkErrorToastProps = getExpiredErrorToastProps();
      showToast(expiredLinkErrorToastProps);

      navigateSearch('/link-expired', queryParams);

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError, data?.expired, showToast, showLoader]);

  return refetch;
};
