import { FormProvider, useForm } from 'react-hook-form';
import FileUpload from '../FileUpload/FileUpload';
import { Stack, Button, Box } from '@mui/material';
import LoadingButton from '../LoadingButton/LoadingButton';

// Scss
import './CompanyUpdateLogoForm.scss';
import { useUpdateCompanyLogoMutation } from '../../../features/company/companySlice';
import {
  CompanyUpdateLogoFormValues,
  CompanyUpdateLogoFormProps,
} from './interface';
import { useAppSelector } from '../../../config/redux/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyUpdateLogoFormValidationSchema } from './validations/companyUpdateLogoFormValidationSchema';

const CompanyUpdateLogoForm = ({ onSuccess }: CompanyUpdateLogoFormProps) => {
  const logoUrl = useAppSelector((state) => state.logo.url);
  const methods = useForm<CompanyUpdateLogoFormValues>({
    resolver: yupResolver(companyUpdateLogoFormValidationSchema),
    mode: 'onChange',
    defaultValues: {
      logo: logoUrl,
    },
  });
  const { handleSubmit, watch } = methods;
  const hasLogo = watch('logo');

  const [updateCompanyLogo, { isLoading }] = useUpdateCompanyLogoMutation();

  const handleOnSubmit = (data: CompanyUpdateLogoFormValues) => {
    if (data.logo instanceof File) {
      return updateCompanyLogo(data).then(onSuccess);
    }

    onSuccess();
  };

  return (
    <Box className='onboarding-step-form-container'>
      <FormProvider {...methods}>
        <form
          className='company-upload-logo-form'
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Box className='onboarding-step-form'>
            <Box className='file-upload-container'>
              <FileUpload name='logo' progress={100} isUploading={false} />
            </Box>
          </Box>

          <Box className='onboarding-step-actions'>
            <Stack
              className='company-upload-logo-form__actions'
              direction='row'
              spacing={2}
            >
              <Button
                size='large'
                variant='outlined'
                onClick={onSuccess}
                disabled={!!hasLogo}
              >
                {'I’ll do it later'}
              </Button>

              <LoadingButton
                loading={isLoading}
                size='large'
                variant='contained'
                type='submit'
                disabled={!hasLogo}
              >
                ready to go
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CompanyUpdateLogoForm;
