// Libraries
import { useController, useFormContext } from 'react-hook-form';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { Box, FormHelperText, Grid, Link, Typography } from '@mui/material';

// Components
import FileUploadProgress from '../FileUploadProgress/FileUploadProgress';

// Files
import { FileUploadProps } from './interfaces';

// Scss
import './FileUpload.scss';

const FileUpload = ({
  name,
  title,
  className,
  progress,
  isUploading,
}: FileUploadProps) => {
  // Refs
  const dragDropSpace = useRef<HTMLDivElement | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  // Control
  const { control, resetField } = useFormContext();
  const {
    field: { value, onChange, ref, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const activeLogoSrc =
    value instanceof File ? URL.createObjectURL(value) : value;

  const handleUploadFile = (fileList: FileList | null) => {
    if (!fileList || fileList.length < 1 || fileList.length > 1) {
      return;
    }

    const file = fileList[0];
    onChange(file);
    onBlur();
  };

  // Events
  const handleReset = () => {
    resetField(name);
  };

  const handelRef = (e: HTMLInputElement) => {
    ref(e);
    inputFileRef.current = e;
  };

  const handleUploadClick = () => {
    inputFileRef?.current?.click();
  };

  // Handle Upload Drag Events
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleUploadFile(e.target.files);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    handleUploadFile(e.dataTransfer.files);
  };

  return (
    <Grid container className={`${className} file-upload`}>
      {title && (
        <Typography variant='h6' className='file-upload-header'>
          {title}
        </Typography>
      )}

      <div
        className='file-upload-drag-drop-space'
        ref={dragDropSpace}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!!activeLogoSrc && (
          <Box className='file-upload-logo-container'>
            <img
              className='file-upload-logo'
              src={activeLogoSrc}
              alt='company-logo'
              style={{
                visibility: !activeLogoSrc ? 'hidden' : 'visible',
              }}
            />
          </Box>
        )}

        <Typography variant='subtitle1' className='file-upload-text'>
          <Link className='file-upload-button' onClick={handleUploadClick}>
            Click to upload
          </Link>
          or drag and drop
        </Typography>

        <Typography variant='body2' className='file-upload-requirements'>
          .PNG, .JPG, .JPEG, or .GIF (max. 3MB)
        </Typography>

        <input
          ref={handelRef}
          name={name}
          value={value?.fileName}
          onChange={handleChange}
          type='file'
          style={{ display: 'none' }}
          accept='.jpg,.jpeg,.png,.gif'
        />
      </div>

      <FileUploadProgress
        file={value}
        error={!!error}
        loading={isUploading}
        progress={progress}
        onReset={handleReset}
      />

      <FormHelperText error={!!error}>
        {error ? error.message : ''}
      </FormHelperText>
    </Grid>
  );
};

export default FileUpload;
