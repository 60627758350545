// Files
import { StyledAlertProps } from './../../components/StyledAlert/interface';
import {
  STORE_UPDATE_SUCCESS_TITLE,
  STORE_UPDATE_SUCCESS_MESSAGE,
  STORE_UPDATE_ERROR_TITLE,
  STORE_UPDATE_ERROR_MESSAGE,
  STORE_ADD_SUCCESS_TITLE,
  STORE_ADD_SUCCESS_MESSAGE,
  STORE_ADD_ERROR_TITLE,
  STORE_ADD_ERROR_MESSAGE,
  STORE_REMOVE_SUCCESS_TITLE,
  STORE_REMOVE_SUCCESS_MESSAGE,
  STORE_REMOVE_ERROR_TITLE,
  STORE_REMOVE_ERROR_MESSAGE,
} from '../../constants/toastConfig';

const DEFAULT_STORE_NAME = 'Store';

export const getStoreUpdateSuccessToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_UPDATE_SUCCESS_TITLE,
    message: `${name}${' '}${STORE_UPDATE_SUCCESS_MESSAGE}`,
    severity: 'success',
    persist: false,
  };
};

export const getStoreUpdateErrorToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_UPDATE_ERROR_TITLE,
    message: `${name}${' '}${STORE_UPDATE_ERROR_MESSAGE}`,
    severity: 'error',
    persist: false,
  };
};

export const getStoreAddSuccessToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_ADD_SUCCESS_TITLE,
    message: `${name}${' '}${STORE_ADD_SUCCESS_MESSAGE}`,
    severity: 'success',
    persist: false,
  };
};

export const getStoreAddErrorToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_ADD_ERROR_TITLE,
    message: `${name}${' '}${STORE_ADD_ERROR_MESSAGE}`,
    severity: 'error',
    persist: false,
  };
};

export const getStoreDeleteSuccessToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_REMOVE_SUCCESS_TITLE,
    message: `${name}${' '}${STORE_REMOVE_SUCCESS_MESSAGE}`,
    severity: 'success',
    persist: false,
  };
};

export const getStoreDeleteErrorToastProps = (
  name: string = DEFAULT_STORE_NAME,
): StyledAlertProps => {
  return {
    title: STORE_REMOVE_ERROR_TITLE,
    message: `${name}${' '}${STORE_REMOVE_ERROR_MESSAGE}`,
    severity: 'error',
    persist: false,
  };
};
